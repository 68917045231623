import { afterNextRender, Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ExclamationBoxComponent } from '../../shared/components/exclamation-box/exclamation-box.component';

@Component({
    selector: 'ax-kfz-sachverstaendigen-software',
    standalone: true,
    imports: [ExclamationBoxComponent],
    templateUrl: './kfz-sachverstaendigen-software.component.html',
    styleUrl: './kfz-sachverstaendigen-software.component.scss',
})
export class KfzSachverstaendigenSoftwareComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    protected featuresGridExpanded: boolean = false;

    constructor() {
        this.titleService.setTitle('Software für Kfz-Gutachter');
        this.metaService.addTag({
            name: 'description',
            content: 'Entdecke die Funktionen der Kfz-Sachverständigen-Software autoiXpert.',
        });

        // Only runs in client
        afterNextRender(() => {
            this.registerPlayVideoOnScrollIntoViewport();
        });
    }

    //*****************************************************************************
    //  Animated GIFs / Screencasts
    //****************************************************************************/
    registerPlayVideoOnScrollIntoViewport() {
        const screencasts = $('video.feature-preview');

        // Register the play GIF function when the element scrolls into view
        (screencasts as any).waypoint({
            handler: function () {
                // Videos
                $(this.element).attr('loop', 'true');
                this.element.play();

                // Destroy the waypoint to not trigger the play function again
                this.destroy();
            },
            // Trigger when the element hits the bottom of the screen
            offset: '100%',
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Animated GIFs / Screencasts
    /////////////////////////////////////////////////////////////////////////////*/

    openYoutubeVideo(): void {
        // Create new <a> element because window.open opens a popup window in Chrome.
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: 'https://www.youtube.com/c/autoixpert',
        }).click();
    }
}
