<section id="pricing-intro-section">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-md-6">
                <h3 class="additional-headline">Der Turbo für dein Gutachten-Business</h3>
                <h1>10% Rabatt für modal-Kunden</h1>
                <p>Hast du deine Ausbildung zum Kfz-Sachverständigen bei Modal absolviert?</p>
                <p>
                    Dann starte dein Gutachten-Business mit einem starken Wettbewerbsvorteil:
                    <br />
                    Nutze die modernste Software am Markt
                    <strong>drei Monate lang</strong>
                    zu
                    <br />
                    günstigeren Konditionen als deine Mitbewerber.
                </p>
                <img src="/assets/images/logos/modal-logo.png" alt="Modal-Logo" />
            </div>
        </div>
    </div>
</section>

<section id="plans-section">
    <div class="container">
        <div class="subscription-plans-container row justify-content-center">
            <!--********** modal-Special **********-->
            <div class="col-md-4">
                <div id="office-account-container" class="subscription-plan-container {{ paymentCycle }}">
                    <div class="subscription-plan-icon-container">
                        <img class="subscription-plan-icon" src="/assets/images/crown.svg" alt="Premium Plan Icon" />
                    </div>
                    <h2 class="subscription-plan-title">modal Special</h2>

                    <div class="subscription-plan-features-container">
                        <div class="subscription-plan-feature number-of-users-included">
                            1 Nutzer
                            <span
                                class="help-indicator fancy-tooltip"
                                title="Der erste Nutzer ist inklusive. Zusätzliche Nutzer sind gemäß Preisliste hinzubuchbar.">
                                ?
                            </span>
                        </div>
                        <div class="subscription-plan-feature">30 Tage kostenfrei testen</div>
                        <div class="feature-depending-on-payment-cycle">
                            <div class="subscription-plan-feature monthly">3 Monate 10% Rabatt</div>
                            <div class="subscription-plan-feature annually">1 Jahr 10% Rabatt</div>
                        </div>
                        <div class="subscription-plan-feature">Alle Funktionen</div>
                        <div class="subscription-plan-feature">
                            <a href="https://wissen.autoixpert.de" target="_blank" rel="noopener">
                                Online Knowledge Base
                            </a>
                            <span
                                class="help-indicator fancy-tooltip"
                                title="Durchsuchbare Online-Hilfe, um selbständig starten zu können.">
                                ?
                            </span>
                        </div>
                        <div class="subscription-plan-feature">
                            <a
                                href="https://www.youtube.com/channel/UCJNnRq0fR3OnndjbMuoN30A"
                                target="_blank"
                                rel="noopener">
                                Video-Tutorials
                            </a>
                            <span class="help-indicator fancy-tooltip" title="Videos mit Anleitungen & Tipps.">?</span>
                        </div>
                        <div class="subscription-plan-feature">
                            Telefon-Support
                            <span class="help-indicator fancy-tooltip" title="Mo - Fr, 9 - 17 Uhr">?</span>
                        </div>
                        <div class="subscription-plan-feature">Unlimitierte Anzahl von Gutachten</div>
                        <div class="subscription-plan-feature">
                            Gutachten mit eigenem Logo & Design
                            <span
                                class="help-indicator fancy-tooltip"
                                title="Wähle aus zwei professionell designten Vorlagen oder designe deine eigene Vorlage.">
                                ?
                            </span>
                        </div>
                    </div>

                    <div class="subscription-plan-price-container {{ paymentCycle }}">
                        <div class="subscription-plan-price monthly">
                            <div class="subscription-plan-regular-price">statt 89 €</div>
                            80,10 €
                            <div class="subscription-plan-billing-period">netto pro Monat</div>
                        </div>
                        <div class="subscription-plan-price annually">
                            <div class="subscription-plan-regular-price">statt 85 €</div>
                            76,50 €
                            <div class="subscription-plan-billing-period">netto pro Monat</div>
                            <div class="subscription-plan-price-annual-payment">918 € im Jahr</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--********** Additional users **********-->
            <div class="col-md-4">
                <div class="subscription-plan-container {{ paymentCycle }}">
                    <div class="subscription-plan-icon-container">
                        <img
                            class="subscription-plan-icon"
                            src="/assets/images/checkmark.svg"
                            alt="Vorgehensweise Icon" />
                    </div>
                    <h2 class="subscription-plan-title">Klingt gut?</h2>

                    <div class="subscription-plan-features-container">
                        <div class="subscription-plan-feature">
                            Dann starte mit 30 Tagen kostenfreiem Test & nenne uns bei Vertragsabschluss einfach das
                            Modalseminar, an dem du teilgenommen hast.
                        </div>
                    </div>
                    <a class="subscription-plan-register" href="/registrierung" target="_blank" rel="noopener">
                        Registrieren
                        <br />
                        <span class="label">30 Tage testen</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="payment-period-section">
    <div class="container">
        <div class="row">
            <div id="billing-period-container" class="col-md-12">
                <div id="billing-period-title">Zahlweise</div>
                <div id="billing-period-selection-container">
                    <div
                        id="billing-period-label-monthly"
                        [class.active]="paymentCycle === 'monthly'"
                        (click)="setPaymentCycle('monthly')">
                        Monatlich
                    </div>
                    <div (click)="togglePaymentCycle($event)" id="billing-period-switch-background">
                        <div
                            [ngClass]="{
                                annually: paymentCycle === 'annually',
                                monthly: paymentCycle === 'monthly'
                            }"
                            id="billing-period-switch">
                            &nbsp;
                        </div>
                    </div>
                    <div
                        [class.active]="paymentCycle === 'annually'"
                        (click)="setPaymentCycle('annually')"
                        id="billing-period-label-annually">
                        Jährlich
                    </div>
                </div>
                <div id="billing-period-conditions-container">
                    <div
                        id="billing-conditions-monthly"
                        class="billing-condition"
                        [class.hidden]="paymentCycle === 'annually'">
                        Monatlich kündbar
                        <span
                            class="help-indicator fancy-tooltip"
                            title="Warum monatlich kündbar? Wir sind uns sicher, dass autoiXpert dich begeistern wird.">
                            ?
                        </span>
                    </div>
                    <div
                        id="billing-conditions-annually"
                        class="billing-condition"
                        [class.hidden]="paymentCycle === 'monthly'">
                        12 Monate Laufzeit, einmalige Zahlung im Voraus
                        <span
                            class="help-indicator fancy-tooltip"
                            title="Profitiere von geringeren Kosten, wenn du dich für 12 Monate festlegst. Die Kündigungsfrist beträgt nur 14 Tage zum Ende der Laufzeit.">
                            ?
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ax-total-price-calculator-banner></ax-total-price-calculator-banner>
<ax-pricing-faq></ax-pricing-faq>
