export function initializeTooltipster(): void {
    const elementsWithTooltip = $('.fancy-tooltip');

    // Remove all before initializing them again
    ($ as any).tooltipster.instances().map(function (tooltipsterInstance: any) {
        // Only destroy tooltips generated by a data-tooltip-content reference. Initializing a tooltip from the title attribute removes the title attribute, so that a 2nd initialization is impossible.
        const domElement = tooltipsterInstance.elementOrigin();
        if ($(domElement).attr('data-tooltip-content')) {
            tooltipsterInstance.destroy();
        }
    });

    (elementsWithTooltip as any).tooltipster({
        theme: 'tooltipster-borderless',
        arrow: true,
        delay: 200,
        maxWidth: 400,
        trigger: 'custom',
        triggerOpen: {
            mouseenter: true,
            click: true,
            tap: true,
        },
        triggerClose: {
            mouseleave: true,
            originClick: true,
            click: true,
            tap: true,
        },
        contentCloning: true,
    });
}
