<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Nachdem im Webinar
        <i>Grundlagen Fahrzeugbewertung</i>
        die wichtigsten Grundlagen erläutert wurden und eine erste Fahrzeugbewertung durchgeführt wurde, gehen wir im
        Aufbauwebinar auf die Details einer Fahrzeugbewertung ein. Neben der praktischen Bewertung wertrelevanter
        Faktoren am Standard-PKW wird ebenso die manuelle Bewertung am Beispiel eines LKW mit Aufbau durchgeführt, um
        auch die manuelle Bewertung einzelner Komponenten im Blick zu haben.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die die Grundlagen zum Thema Fahrzeugbewertung kennenlernen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
