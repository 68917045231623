<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Ein Sachverständigengutachten wird unter Zuhilfenahme eines Büroverwaltungssystems in der Regel aus
        Textbausteinen erstellt, die automatisiert in das Gutachten aufgenommen werden. Zur Erstellung eines sachlich
        und fachlich richtigen Gutachtens ist es jedoch notwendig, die Textbausteine auf den individuellen Sachverhalt
        anzupassen. Das Seminar verfolgt das Ziel, die üblichen Textbausteine kennenzulernen, zu individualisieren und
        zu optimieren.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die Grundlagen zum Aufbau eines Sachverständigengutachtens erwerben oder ihre Textbausteine
    optimieren möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
