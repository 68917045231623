<section id="event-signup-section">
    @if (!signupSuccessful && !eventOverbooked && !duplicate) {
        <form
            id="event-signup-container"
            class="container"
            (submit)="sendEventSignupForm(webinarDate); $event.preventDefault()">
            <div class="row">
                <div id="event-signup-heading-container" class="col-md-12">
                    <h2 id="event-signup-heading">Sei mit dabei!</h2>
                    <p id="event-signup-release-note">
                        @if (isOnlineExchange) {
                            Online-Teilnahme
                        } @else {
                            Online-Fachwebinar
                        }
                        <span class="d-none d-md-inline">-</span>
                        <br class="d-md-none" />
                        {{ webinarDate | getWebinarDateLongFormWithWeekday }} - {{ webinarDate | getWebinarTime }} bis
                        {{ webinarDate | getWebinarTime: duration * 60 }} Uhr
                        <br />
                        @if (price) {
                            Teilnahmegebühr:
                            <strong>{{ price }} € pro Person</strong>
                            (zzgl. MwSt.).
                            <br />
                            Die Rechnung erhältst du kurz vor dem Webinar.
                        } @else {
                            Kostenfreie Teilnahme.
                        }
                    </p>
                </div>
            </div>
            <div class="row justify-content-center event-signup-row">
                <div class="col-md-4">
                    <input
                        type="text"
                        class="newsletter-input"
                        [(ngModel)]="organization"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Name deines SV-Büros"
                        required />
                </div>
            </div>
            <div class="row justify-content-center event-signup-row">
                <div class="col-md-2">
                    <input
                        type="text"
                        class="newsletter-input"
                        [(ngModel)]="firstName"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Vorname"
                        required />
                </div>
                <div class="col-md-2">
                    <input
                        type="text"
                        class="newsletter-input"
                        [(ngModel)]="lastName"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Nachname"
                        required />
                </div>
            </div>
            <div class="row justify-content-center event-signup-row">
                <div class="col-md-4 email-with-error-message">
                    <input
                        #emailInput="ngModel"
                        [(ngModel)]="email"
                        [ngModelOptions]="{ standalone: true }"
                        email
                        [pattern]="emailValidationRegex"
                        class="newsletter-input"
                        type="email"
                        placeholder="E-Mail"
                        required />
                    <span class="error-message" *ngIf="emailInput.invalid && emailInput.touched">
                        E-Mail-Adresse nicht gültig.
                    </span>
                </div>
            </div>
            @if (price) {
                <h3 id="invoice-address-heading">Rechnungsadresse</h3>
                <div class="row justify-content-center event-signup-row">
                    <div class="col-md-4">
                        <input
                            type="text"
                            class="newsletter-input"
                            [(ngModel)]="street"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Straße"
                            required />
                    </div>
                </div>
                <div class="row justify-content-center event-signup-row">
                    <div class="col-md-2">
                        <input
                            type="text"
                            class="newsletter-input"
                            [(ngModel)]="zip"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="PLZ"
                            required />
                    </div>
                    <div class="col-md-2">
                        <input
                            type="text"
                            class="newsletter-input"
                            [(ngModel)]="city"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Ort"
                            required />
                    </div>
                </div>
                <!--********** Cancellation Policy **********-->
                <div class="row justify-content-center event-signup-row">
                    <div class="col-md-4">
                        <div class="label text-center">
                            Du kannst deine Anmeldung bis 12 Uhr am Tag des Webinars kostenfrei stornieren.
                        </div>
                    </div>
                </div>
            }
            <div class="row justify-content-center event-signup-row">
                <div class="col-md-4">
                    <button
                        type="submit"
                        [class.disallowed]="disallowed()"
                        [title]="disallowed() ? 'Bitte fülle das Formular vollständig aus.' : ''"
                        id="newsletter-submit-button"
                        class="newsletter-input">
                        @if (signupPending) {
                            <img src="/assets/images/loading-indicator-white.svg" alt="" class="pending-indicator" />
                        }
                        @if (price) {
                            Kostenpflichtig buchen
                        } @else {
                            Anmelden
                        }
                    </button>
                </div>
            </div>
        </form>
    }
    @if (signupSuccessful || eventOverbooked || duplicate) {
        <div id="event-signup-response-container" class="container">
            @if (signupSuccessful) {
                <div id="event-signup-successful">
                    <h2>Anmeldung erfolgreich</h2>
                    <p>Du hast eine Termin-Bestätigung per E-Mail von uns bekommen.</p>
                    @if (price) {
                        <p>
                            Die
                            <strong>Rechnung</strong>
                            und die
                            <strong>Zugangsdaten</strong>
                            bekommst du am Tag des Webinars.
                        </p>
                    }
                    <div>
                        <a href="javascript:void(0)" (click)="showSignupForm()">Weitere Person registrieren</a>
                        oder
                        <a href="/Registrierung">autoiXpert 30 Tage kostenfrei testen</a>
                    </div>
                </div>
            }
            @if (eventOverbooked) {
                <div id="event-overbooked-container">
                    <h2>Event bereits ausgebucht</h2>
                    <div>Es tut uns leid, aber das Event ist bereits ausgebucht. Weitere Webinare sind geplant.</div>
                </div>
            }
            @if (duplicate) {
                <div id="event-duplicate-email-container">
                    <h2>Bereits registriert</h2>
                    <div>Es hat sich bereits jemand mit diesem Namen und dieser E-Mail-Adresse registriert.</div>
                </div>
            }
        </div>
    }
</section>
