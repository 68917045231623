<div id="page-container" class="container">
    <div class="row justify-content-center">
        <div class="col-md-7">
            <img
                src="/assets/images/qapter-screen-with-woman.jpg"
                class="blog-image"
                alt="Frau mit Qapter-Software auf dem Bildschirm" />
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-7">
            <h3 class="additional-headline">autoiXpert & Audatex</h3>
            <h2>Strategische Partnerschaft</h2>

            <!--********** Intro **********-->
            <p>
                AutoiXpert und Audatex-AUTOonline haben Mitte Juni 2021 ihre strategische Partnerschaft bekanntgegeben.
                <br />
                Für die Kunden beider Unternehmen bringt sie zwei zentrale Vorteile:
            </p>

            <!--********** In a Nutshell - List **********-->
            <ul>
                <li>
                    <strong>Schnittstellen</strong>
                    zur neuen Online-Kalkulationssoftware Qapter und AUTOonline für autoiXpert-Nutzer
                </li>
                <li>
                    Moderne Gutachtensoftware
                    <strong>Qapter-iXpert</strong>
                    auf Basis der Oberfläche von autoiXpert für heutige AudaFusion-Nutzer
                </li>
            </ul>

            <h3>Schnittstellen zu Qapter & AUTOonline</h3>
            <p>
                Für alle autoiXpert-Nutzer wird es die Möglichkeit geben, Qapter mithilfe eines optionalen Moduls (30 €
                pro Monat & Büro; 10 € pro Zusatznutzer) an autoiXpert anzubinden. Qapter wird ähnlich zur
                DAT-Kalkulation integriert, sodass die VIN-Abfrage und der Absprung zur Kalkulationssoftware genauso
                einfach sein wird.
                <br />
                AUTOonline wird für alle Nutzer unabhängig vom Zusatzmodul verfügbar sein und so komfortabel wie die
                anderen Restwertbörsen integriert.
                <br />
                <br />
                Die Preise von Qapter & AUTOonline kannst du direkt
                <a href="mailto:info@ax-ao.de?subject=Infos zu Qapter Preisen" target="_blank" rel="noopener">
                    bei Audatex anfragen
                </a>
                .
            </p>

            <h3>Gutachtensoftware Qapter-iXpert</h3>
            <p>
                Das Unternehmen autoiXpert stellt in enger Kooperation mit Audatex die Produktvariante Qapter-iXpert zur
                Verfügung, die im Funktionsumfang dem Produkt autoiXpert gleicht und von uns betrieben wird. Zentraler
                Unterschied: Optisch wird Qapter-iXpert die gewohnten Farben aus der Audatex-Suite aufgreifen, um vor
                allem für Audatex-Bestandskunden einen gewohnten Umgang zu bieten.
                <br />
                Die Schnittstellen zu Qapter und AUTOonline ebenso wie alle bisherigen Schnittstellen, z. B. zu
                SilverDAT 3, sind in Qapter-iXpert genauso vorhanden wie in autoiXpert.
            </p>

            <h3>Veränderungen für Bestandskunden?</h3>
            <p>
                Ohne dein Zutun ändert sich nichts. Wenn du Interesse an Qapter und AUTOonline hast, buche dir das
                Audatex-Modul hinzu. Ansonsten bleibt alles beim Alten, auch der Preis von 89 €.
            </p>

            <h3>Howdy, Partner! - Partnerschaft mit Audatex</h3>
            <p>
                autoiXpert bleibt ein eigenständiges Unternehmen mit der Flexibilität und dem Kunden-Service, den du von
                uns gewohnt bist. On Top kommen nun die Expertise und die Vernetzung des globalen
                Solera-Audatex-Konzerns mit Sitz in Texas. Eigenständigkeit meinen wir ernst,
                <a href="https://www.ax-ao.de/" target="_blank" rel="noopener">Audatex</a>
                übernimmt keine Anteile von autoiXpert.
            </p>

            <!--********** Spacer **********-->
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col text-center">
            <p>
                <a href="/" class="button-solid">Zur Startseite</a>
            </p>
        </div>
    </div>
</div>
