<section id="become-assessor-intro-section">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-md-6">
                <h3 class="additional-headline">Ausbildung zum Kfz-Sachverständigen</h3>
                <h1>Kfz-Gutachter werden</h1>
                <p>
                    Bereit für den Start in die Selbständigkeit als Kfz-Sachverständiger?
                    <br class="d-none d-md-block" />
                    Eine professionelle Ausbildung und die Mitgliedschaft in einem Berufsverband
                    <br class="d-none d-md-block" />
                    können dir den Einstieg in deinen neuen Beruf um einiges erleichtern.
                </p>
            </div>
        </div>
    </div>
</section>

<section id="training-and-association-section">
    <div class="container">
        <div id="training-and-association-row" class="row justify-content-center">
            <!--============================================-->
            <!-- Training -->
            <!--============================================-->
            <div class="col-md-4">
                <div class="comparison-group">
                    <h2 class="comparison-group-title">Ausbildung</h2>

                    <div class="comparison">
                        <h3 class="comparison-heading">
                            Wer kann ohne
                            <br class="d-none d-md-block" />
                            Ausbildung starten?
                        </h3>
                        <p>
                            Du hast bereits als Sachverständiger gearbeitet und kennst dich mit den rechtlichen
                            Rahmen&shy;bedingungen sowie der Verwaltung eines Büros bestens aus. Du bist startklar!
                        </p>
                    </div>

                    <div class="comparison">
                        <h3 class="comparison-heading">
                            Für wen lohnt sich
                            <br class="d-none d-md-block" />
                            eine Ausbildung?
                        </h3>
                        <p>
                            Du hast zwar einen Hintergrund im Kfz-Wesen, aber bist noch nicht ganz sattelfest bei den
                            rechtlichen Rahmenbedingungen eines Kfz-Sachverständigen oder der Verwaltung eines eigenen
                            Büros.
                        </p>
                    </div>

                    <div class="comparison">
                        <h3 class="comparison-heading">Welche Anbieter gibt es?</h3>
                        <!-- prettier-ignore -->
                        <p>
                            In Deutschland gibt es drei bekanntere Anbieter: Modal, SBW und
                            DGuSV. Wir empfehlen dir daraus die
                            <a
                                href="https://www.modal.de/ausbildung/kfz"
                                target="_blank"
                                rel="noopener">
                                modal GmbH & Co. KG
                            </a>, die deutschlandweit Seminare anbietet und als Bildungsträger
                            staatlich anerkannt ist.
                            <a
                                href="https://www.modal.de/ausbildung/kfz"
                                target="_blank"
                                rel="noopener">
                                <br/>
                                <img
                                    id="modal-logo"
                                    src="/assets/images/logos/modal-logo.png"
                                    alt="Modal-Logo"/>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Training -->
            <!--============================================-->

            <!--============================================-->
            <!-- Association -->
            <!--============================================-->
            <div class="col-md-4">
                <div class="comparison-group">
                    <h2 class="comparison-group-title">Berufsverbände</h2>

                    <div class="comparison">
                        <h3 class="comparison-heading">
                            Für wen lohnt sich
                            <br class="d-none d-md-block" />
                            ein Berufsverband?
                        </h3>
                        Du hast eine Ausbildung zum Kfz-Sachverständigen absolviert oder anderweitig schon lange in dem
                        Geschäft gearbeitet. Du suchst aber noch nach einem Weg, um dich regelmäßig fortzubilden oder
                        dich mit anderen Gutachtern auszutauschen. Außerdem möchtest du als Mitglied auch von der
                        Bekanntheit des Verbandes profitieren.
                    </div>

                    <div class="comparison">
                        <h3 class="comparison-heading">Welche Verbände gibt es?</h3>
                        <div>
                            <a href="https://www.bvsk.de/" target="_blank" rel="noopener">BVSK</a>
                        </div>
                        <div>
                            <a href="https://www.vks.org/" target="_blank" rel="noopener">VKS</a>
                        </div>
                        <div>
                            <a href="https://www.desag.de/" target="_blank" rel="noopener">DESAG</a>
                        </div>
                        <div>...und weitere</div>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Association -->
            <!--============================================-->
        </div>
    </div>
</section>
