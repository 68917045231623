<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Dieses Webinar bietet Ihnen einen detaillierten Einblick in die Bedeutung und die Anforderungen von Nachtexten
        in Gutachten. Das Seminar beinhaltet praktische Beispiele für mögliche Textoptimierungen und zeigt Ihnen, wie
        Sie Nachtexte effektiv gestalten können.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die die Grundlagen zum Thema Nachtexte kennenlernen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
