<section class="webinar-hero-section">
    <div id="keynote-hero-header-container-muenchen" class="webinar-hero-header-container">
        <h3 class="additional-headline">Fachwebinar</h3>
        <h2 class="webinar-hero-heading">{{ webinarTitleShort }}</h2>
        <div class="webinar-hero-additional-info">
            {{ webinarDate | getWebinarDateLongForm }}
            <br />
            {{ webinarDate | getWebinarTime }} - {{ webinarDate | getWebinarTime: duration * 60 }} Uhr
            <div>{{ price > 0 ? price + ' € pro Teilnehmer' : 'Kostenfrei' }}</div>
            <button
                id="webinar-register-link-button"
                class="newsletter-input primary-button"
                (click)="scrollElemIntoView('#event-signup-section')">
                Anmelden
            </button>
        </div>
    </div>
</section>
