<section id="pricing-intro-section">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-md-6">
                <h3 class="additional-headline">Alle Funktionen. Ein Preis.</h3>
                <h1 id="pricing-intro-headline">Preise für deine neue Gutachten-Software</h1>
                <p id="pricing-intro-tagline">
                    Software sollte einfach sein, Preise auch.
                    <br />
                    Egal, ob du alleine arbeitest oder im Team, unser Preismodell bleibt simpel.
                </p>
            </div>
        </div>
    </div>
</section>

<div>
    <section id="plans-section">
        <div class="container">
            <div class="subscription-plans-container row justify-content-center">
                <!--********** Office Account **********-->
                <div class="col-md-4">
                    <div id="office-account-container" class="subscription-plan-container annually">
                        <div class="subscription-plan-icon-container">
                            <img
                                class="subscription-plan-icon"
                                src="/assets/images/crown.svg"
                                alt="Premium Plan Icon" />
                        </div>
                        <h2 class="subscription-plan-title">Büro-Account</h2>

                        <div class="subscription-plan-features-container">
                            <div class="subscription-plan-feature number-of-users-included">
                                1 Nutzer
                                <span
                                    class="help-indicator fancy-tooltip"
                                    title="Der erste Nutzer ist inklusive. Zusätzliche Nutzer sind hinzubuchbar.">
                                    ?
                                </span>
                            </div>
                            <div class="subscription-plan-feature">
                                Alle Funktionen
                                <span
                                    v-if="!audatexAddon"
                                    class="help-indicator fancy-tooltip"
                                    title="Alle Funktionen außer Audatex. Für die Schnittstelle zu Audatex Qapter benötigst du das Audatex-Add-on.">
                                    ?
                                </span>
                            </div>
                            <div class="subscription-plan-feature">
                                <a href="https://wissen.autoixpert.de" target="_blank" rel="noopener">
                                    Online Knowledge Base
                                </a>
                                <span
                                    class="help-indicator fancy-tooltip"
                                    title="Durchsuchbare Online-Hilfe, um selbständig starten zu können.">
                                    ?
                                </span>
                            </div>
                            <div class="subscription-plan-feature">
                                <a
                                    href="https://www.youtube.com/channel/UCJNnRq0fR3OnndjbMuoN30A"
                                    target="_blank"
                                    rel="noopener">
                                    Video-Tutorials
                                </a>
                                <span class="help-indicator fancy-tooltip" title="Videos mit Anleitungen & Tipps.">
                                    ?
                                </span>
                            </div>
                            <div class="subscription-plan-feature">
                                Telefon-Support
                                <span class="help-indicator fancy-tooltip" title="Mo - Fr, 9 - 17 Uhr">?</span>
                            </div>
                            <div class="subscription-plan-feature">Unlimitierte Anzahl von Gutachten</div>
                            <div class="subscription-plan-feature">
                                Gutachten mit eigenem Logo & Design
                                <span
                                    class="help-indicator fancy-tooltip"
                                    title="Wähle aus zwei professionell designten Vorlagen oder designe deine eigene Vorlage.">
                                    ?
                                </span>
                            </div>
                        </div>

                        <!--********** Pricing **********-->
                        <div class="subscription-plan-price-container">
                            @if (paymentCycle === 'monthly' && !audatexAddon) {
                                <!--********** Base Plan Monthly **********-->
                                <div class="subscription-plan-price" @fadeInAndSlide>
                                    89,- €
                                    <div class="subscription-plan-billing-period">netto pro Monat</div>
                                </div>
                            }
                            @if (paymentCycle === 'annually' && !audatexAddon) {
                                <!--********** Base Plan Annually **********-->
                                <div class="subscription-plan-price" @fadeInAndSlide>
                                    85,- €
                                    <div class="subscription-plan-billing-period">netto pro Monat</div>
                                    <div class="subscription-plan-price-annual-payment">1.020,- € im Jahr</div>
                                </div>
                            }
                            @if (audatexAddon) {
                                <!--********** Incl. Audatex Addon **********-->
                                <div class="subscription-plan-price" @fadeInAndSlide>
                                    119,- €
                                    <div class="subscription-plan-billing-period">netto pro Monat</div>
                                    @if (paymentCycle === 'annually') {
                                        <div class="subscription-plan-price-annual-payment" @fadeInAndSlideFast>
                                            1.428,- € im Jahr
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <a class="subscription-plan-register" href="/registrierung" target="_blank" rel="noopener">
                            Registrieren
                            <br />
                            <span class="label">30 Tage testen</span>
                        </a>
                    </div>
                </div>

                <!--********** Right Column **********-->
                <div class="col-md-4">
                    <!--********** Additional users **********-->
                    <div class="subscription-plan-container">
                        <div class="subscription-plan-icon-container">
                            <img
                                class="subscription-plan-icon"
                                src="/assets/images/badge.svg"
                                alt="Additional Account Icon" />
                        </div>
                        <h2 class="subscription-plan-title">Zusatznutzer</h2>

                        <div class="subscription-plan-features-container">
                            <div class="subscription-plan-feature number-of-users-included">
                                + 1 Nutzer
                                <span
                                    class="help-indicator fancy-tooltip"
                                    title="Unbegrenzte Zahl von Zusatznutzern möglich">
                                    ?
                                </span>
                            </div>
                        </div>

                        <div class="subscription-plan-price-container">
                            @if (!audatexAddon) {
                                <!--********** Base Plan **********-->
                                <div class="subscription-plan-price" @fadeInAndSlide>
                                    20,- €
                                    <div class="subscription-plan-billing-period">netto pro Monat</div>
                                    @if (paymentCycle === 'annually') {
                                        <div class="subscription-plan-price-annual-payment" @fadeInAndSlideFast>
                                            240,- € im Jahr
                                        </div>
                                    }
                                </div>
                            }
                            @if (audatexAddon) {
                                <!--********** Incl. Audatex Add-on **********-->
                                <div class="subscription-plan-price" @fadeInAndSlide>
                                    30,- €
                                    <div class="subscription-plan-billing-period">netto pro Monat</div>
                                    @if (paymentCycle === 'annually') {
                                        <div class="subscription-plan-price-annual-payment" @fadeInAndSlideFast>
                                            360,- € im Jahr
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>

                    <!--********** Audatex Add-on **********-->
                    <div id="audatex-addon-container" class="subscription-plan-container">
                        <h2 class="subscription-plan-title">Audatex Add-on</h2>

                        <div class="subscription-plan-features-container">
                            <div>
                                Schnittstelle zu Audatex Qapter
                                <span
                                    class="help-indicator fancy-tooltip"
                                    title="Anbindung von VIN-Abfrage, Kalkulation, Bewertung, Mietwagenspiegel über Audatex Qapter">
                                    ?
                                </span>
                            </div>
                        </div>

                        <div id="audatex-addon-switch-container">
                            <div
                                id="audatex-addon-label-off"
                                [class.active]="!audatexAddon"
                                (click)="setAudatexAddon(false)">
                                Nein
                            </div>
                            <div
                                id="audatex-addon-switch-background"
                                [ngClass]="{ on: audatexAddon, off: !audatexAddon }"
                                (click)="toggleAudatexAddon($event)">
                                <div id="audatex-addon-switch" [ngClass]="{ on: audatexAddon, off: !audatexAddon }">
                                    &nbsp;
                                </div>
                            </div>
                            <div
                                id="audatex-addon-label-on"
                                [class.active]="audatexAddon"
                                (click)="setAudatexAddon(true)">
                                Ja
                            </div>
                        </div>

                        <div class="label">
                            Datenvertrag mit Audatex erforderlich
                            <a href="/audatex-preisrechner" target="_blank">
                                <span
                                    class="help-indicator fancy-tooltip"
                                    title="Neben der Schnittstelle benötigst du auch einen separaten Vertrag mit Audatex für die Fahrzeugdaten (Produktname Qapter). Klicke für Preise zu Qapter.">
                                    ?
                                </span>
                            </a>
                        </div>
                        <div id="link-to-audatex-pricing-calculator" class="label">
                            @if (audatexAddon) {
                                <a href="/audatex-preisrechner" target="_blank" @fadeInAndSlide>Preise</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="payment-period-section">
        <div class="container">
            <div class="row">
                <div id="billing-period-container" class="col-md-12">
                    <div id="billing-period-title">Zahlweise</div>
                    <div id="billing-period-selection-container">
                        <div
                            id="billing-period-label-monthly"
                            [class.active]="paymentCycle === 'monthly'"
                            (click)="setPaymentCycle('monthly')">
                            Monatlich
                        </div>
                        <div id="billing-period-switch-background" (click)="togglePaymentCycle($event)">
                            <div
                                id="billing-period-switch"
                                [ngClass]="{
                                    annually: paymentCycle === 'annually',
                                    monthly: paymentCycle === 'monthly'
                                }">
                                &nbsp;
                            </div>
                        </div>
                        <div
                            id="billing-period-label-annually"
                            [class.active]="paymentCycle === 'annually'"
                            (click)="setPaymentCycle('annually')">
                            Jährlich
                        </div>
                    </div>
                    <div id="billing-period-conditions-container">
                        @if (paymentCycle === 'monthly') {
                            <div @fadeInAndSlide id="billing-conditions-monthly" class="billing-condition">
                                Monatlich kündbar
                                <span
                                    class="help-indicator fancy-tooltip"
                                    title="Warum monatlich kündbar? Wir wollen dich mit unserer Software lieber jeden Monat überzeugen, als dass du nur Kunde bist, weil du vertraglich an uns gebunden bist.">
                                    ?
                                </span>
                            </div>
                        }
                        @if (paymentCycle === 'annually') {
                            <div id="billing-conditions-annually" class="billing-condition" @fadeInAndSlide>
                                12 Monate Laufzeit, einmalige Zahlung im Voraus
                                <span
                                    class="help-indicator fancy-tooltip"
                                    title="Profitiere von geringeren Kosten, wenn du dich für 12 Monate festlegst. Die Kündigungsfrist beträgt nur 14 Tage zum Ende der Laufzeit.">
                                    ?
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<ax-total-price-calculator-banner></ax-total-price-calculator-banner>
<ax-pricing-faq></ax-pricing-faq>
