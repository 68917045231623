//*****************************************************************************
//  Formatters
//****************************************************************************/
export const formatPrice = (price: number, fractionDigits = 2) => {
    return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    })
        .format(price)
        .replace(',00', '');
};
export const roundNumber = (number: number) => {
    return Math.round(number);
};
export const roundToHalf = (value: number) => {
    const float = value;
    let decimal = float - parseInt(float.toString(), 10);
    decimal = Math.round(decimal * 10);
    if (decimal === 5) {
        return parseInt(float.toString(), 10) + 0.5;
    }
    if (decimal < 3 || decimal > 7) {
        return Math.round(float);
    } else {
        return parseInt(float.toString(), 10) + 0.5;
    }
};
export const formatNumber = (number: number) => {
    return Number(number).toLocaleString('de-de', {
        maximumFractionDigits: 1,
    });
};
/////////////////////////////////////////////////////////////////////////////*/
//  END Formatters
/////////////////////////////////////////////////////////////////////////////*/

export const parseNumber = (text: string | null): number => {
    return parseInt(text || '0', 10);
};
