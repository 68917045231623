import { Component, inject } from '@angular/core';
import { ConfigService } from '../../services/config.service';

@Component({
    selector: 'ax-footer',
    standalone: true,
    imports: [],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
})
export class FooterComponent {
    protected readonly configService = inject(ConfigService);
}
