import { parseNumber } from './price-calculator-utils';
// Fix Fees
export const STARTER_SETUP_FEE = 1680;
export const DAT_LICENSE = 825;
export const DAT_SETUP_AND_INSTRUCTION = 650;
export const DAT_SETUP_WITHOUT_INSTRUCTION = 300; // Setup without instructions is only used in the per-case plan

// Users
export const USERS_INCLUDED = 2;
export const ADDITIONAL_USERS_FEE = 3.5;

// VIN
export const VIN_CALL_PRICE = 1.45;
// Payment Method Fees
export const MONTHLY_PAYMENT_CYCLE_FEE = 7.5;

// Per Case = small base fee + larger fee per calculation
export const MONTHLY_BASE_FEE_PERCASE = 20;
export const CALL_PRICE_PERCASE_CALCULATION = 15;
export const CALL_PRICE_PERCASE_VALUATION = 8;

// Starter = small gross price package
export const MAXIMUM_NUMBER_OF_MONTHLY_CASES_STARTER = 15;
export const ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION = 100;
export const ANNUAL_CALLS_INCLUDED_STARTER_VALUATION = 100;
export const CALL_PRICE_STARTER_CALCULATION = 8.1;
export const CALL_PRICE_STARTER_VALUATION = 5.3;

// Basic = larger base fee + small fee per calculation
export const MONTHLY_BASE_FEE_BASIC = 135;
export const ANNUAL_CALLS_INCLUDED_BASIC_CALCULATION = 185;
export const CALL_PRICE_BASIC_CALCULATION = 8.1;
export const CALL_PRICE_BASIC_VALUATION = 5.3;

// Flatrate = large gross price package
export const MONTHLY_BASE_FEE_FLATRATE = 284;
export const ANNUAL_CALLS_INCLUDED_FLATRATE = 750;
export const CALL_PRICE_FLATRATE_FROM_751 = 5.4;
export const CALL_PRICE_FLATRATE_FROM_851 = 4.7;
export const CALL_PRICE_FLATRATE_FROM_951 = 4.5;
export const CALL_PRICE_FLATRATE_FROM_1151 = 4.3;

// Paint System Fees
export const MONTHLY_AZT_PAINT_SYSTEM_FEE = 6;

// Rental Car Prices
export const RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE = 29;
export const ANNUAL_CALLS_INCLUDED_RENTAL_CAR = 750;
export const CALL_PRICE_RENTAL_CAR = 1.3;

// Webinars
export const WEBINAR_PRICE = 50;

//*****************************************************************************
//  One-Time Prices
//****************************************************************************/
export const perCaseOneTimeTotal = (): number => {
    return DAT_LICENSE + DAT_SETUP_WITHOUT_INSTRUCTION;
};

export const starterOneTimeTotal = (): number => {
    return STARTER_SETUP_FEE;
};

export const basicOneTimeTotal = (): number => {
    return DAT_LICENSE + DAT_SETUP_AND_INSTRUCTION;
};

export const flatrateOneTimeTotal = (): number => {
    return DAT_LICENSE + DAT_SETUP_AND_INSTRUCTION;
};

/////////////////////////////////////////////////////////////////////////////*/
//  END One-Time Prices
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Availability
//****************************************************************************/
export const perCasePlanAvailable = (
    alreadyDatCustomer: boolean,
    reportsPerMonth: string,
    valuationsPerMonth: string,
): boolean => {
    return !alreadyDatCustomer && parseNumber(reportsPerMonth) <= 10 && parseNumber(valuationsPerMonth) <= 10;
};

export const starterPlanAvailable = (
    alreadyDatCustomer: boolean,
    reportsPerMonth: string,
    valuationsPerMonth: string,
): boolean => {
    return !alreadyDatCustomer && parseNumber(reportsPerMonth) <= 15 && parseNumber(valuationsPerMonth) <= 15;
};

/////////////////////////////////////////////////////////////////////////////*/
//  END Availability
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Usage Prices
//****************************************************************************/
export const vinCalls = (reportsPerMonth: string, valuationsPerMonth: string): number => {
    return Math.max(parseNumber(reportsPerMonth), parseNumber(valuationsPerMonth)) * 12;
};

export const vinCallsPrice = (reportsPerMonth: string, valuationsPerMonth: string): number => {
    return vinCalls(reportsPerMonth, valuationsPerMonth) * VIN_CALL_PRICE;
};

//*****************************************************************************
//  Rental Car Prices
//****************************************************************************/
export const additionalRentalCarPricingCalls = (reportsPerMonth: string): number => {
    return Math.max(parseNumber(reportsPerMonth) * 12 - ANNUAL_CALLS_INCLUDED_RENTAL_CAR, 0);
};

export const additionalRentalCarPricingCallsPrice = (reportsPerMonth: string): number => {
    return additionalRentalCarPricingCalls(reportsPerMonth) * CALL_PRICE_RENTAL_CAR;
};

export const annualRentalCarPricingFees = (
    includeRentalCarPricingService: boolean,
    reportsPerMonth: string,
): number => {
    if (!includeRentalCarPricingService) return 0;

    const baseFee = RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE * 12;
    const overusageFees = additionalRentalCarPricingCallsPrice(reportsPerMonth);

    return baseFee + overusageFees;
};

/////////////////////////////////////////////////////////////////////////////*/
//  END Rental Car Prices
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Per-Case Prices
//****************************************************************************/
export const perCaseAdditionalCallsCalculation = (reportsPerMonth: string): number => {
    return Math.max(parseNumber(reportsPerMonth) * 12, 0);
};

export const perCaseAdditionalCallsCalculationPrice = (reportsPerMonth: string): number => {
    return perCaseAdditionalCallsCalculation(reportsPerMonth) * CALL_PRICE_PERCASE_CALCULATION;
};

export const perCaseAdditionalCallsValuation = (valuationsPerMonth: string): number => {
    return Math.max(parseNumber(valuationsPerMonth) * 12, 0);
};

export const perCaseAdditionalCallsValuationPrice = (valuationsPerMonth: string): number => {
    return perCaseAdditionalCallsValuation(valuationsPerMonth) * CALL_PRICE_PERCASE_VALUATION;
};

export const perCaseAnnualTotal = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    const returningFees =
        (MONTHLY_BASE_FEE_PERCASE + MONTHLY_AZT_PAINT_SYSTEM_FEE + additionalUsersPrice(numberOfUsers)) * 12;
    const usageFees =
        vinCallsPrice(reportsPerMonth, valuationsPerMonth) +
        perCaseAdditionalCallsCalculationPrice(reportsPerMonth) +
        perCaseAdditionalCallsValuationPrice(valuationsPerMonth) +
        annualRentalCarPricingFees(includeRentalCarPricingService, reportsPerMonth);
    return returningFees + usageFees;
};

export const perCaseMonthlyTotal = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        perCaseAnnualTotal(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService) / 12 +
        MONTHLY_PAYMENT_CYCLE_FEE
    );
};

export const perCaseFirstYearFee = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        perCaseOneTimeTotal() +
        perCaseAnnualTotal(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService)
    );
};

export const perCaseFirstYearMonthlyFee = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        perCaseFirstYearFee(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService) / 12 +
        MONTHLY_PAYMENT_CYCLE_FEE
    );
};

/////////////////////////////////////////////////////////////////////////////*/
//  END Per-Case Prices
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Starter Prices
//****************************************************************************/
export const starterAdditionalCallsCalculation = (reportsPerMonth: string): number => {
    return Math.max(parseNumber(reportsPerMonth) * 12 - ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION, 0);
};

export const starterAdditionalCallsCalculationPrice = (reportsPerMonth: string): number => {
    return starterAdditionalCallsCalculation(reportsPerMonth) * CALL_PRICE_STARTER_CALCULATION;
};

export const starterAdditionalCallsValuation = (valuationsPerMonth: string): number => {
    return Math.max(parseNumber(valuationsPerMonth) * 12 - ANNUAL_CALLS_INCLUDED_STARTER_VALUATION, 0);
};

export const starterAdditionalCallsValuationPrice = (valuationsPerMonth: string): number => {
    return starterAdditionalCallsValuation(valuationsPerMonth) * CALL_PRICE_STARTER_VALUATION;
};

export const starterAnnualTotal = (
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        vinCallsPrice(reportsPerMonth, valuationsPerMonth) +
        starterAdditionalCallsCalculationPrice(reportsPerMonth) +
        MONTHLY_AZT_PAINT_SYSTEM_FEE * 12 +
        starterAdditionalCallsValuationPrice(valuationsPerMonth) +
        annualRentalCarPricingFees(includeRentalCarPricingService, reportsPerMonth)
    );
};

export const starterMonthlyTotal = (
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        starterAnnualTotal(reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService) / 12 +
        MONTHLY_PAYMENT_CYCLE_FEE
    );
};

export const starterFirstYearFee = (
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        starterOneTimeTotal() + starterAnnualTotal(reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService)
    );
};

export const starterFirstYearMonthlyFee = (
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        starterFirstYearFee(reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService) / 12 +
        MONTHLY_PAYMENT_CYCLE_FEE
    );
};

/////////////////////////////////////////////////////////////////////////////*/
//  END Starter Prices
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Basic Price
//****************************************************************************/
export const basicAdditionalCallsCalculation = (reportsPerMonth: string): number => {
    // All calls are additional calls on the basic plan
    return Math.max(parseNumber(reportsPerMonth) * 12 - ANNUAL_CALLS_INCLUDED_BASIC_CALCULATION, 0);
};

export const basicAdditionalCallsCalculationPrice = (reportsPerMonth: string): number => {
    return basicAdditionalCallsCalculation(reportsPerMonth) * CALL_PRICE_BASIC_CALCULATION;
};

export const basicAdditionalCallsValuation = (valuationsPerMonth: string): number => {
    // All calls are additional calls on the basic plan
    return parseNumber(valuationsPerMonth) * 12;
};

export const basicAdditionalCallsValuationPrice = (valuationsPerMonth: string): number => {
    return basicAdditionalCallsValuation(valuationsPerMonth) * CALL_PRICE_BASIC_VALUATION;
};

export const basicAnnualTotal = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        vinCallsPrice(reportsPerMonth, valuationsPerMonth) +
        MONTHLY_BASE_FEE_BASIC * 12 +
        basicAdditionalCallsCalculationPrice(reportsPerMonth) +
        MONTHLY_AZT_PAINT_SYSTEM_FEE * 12 +
        basicAdditionalCallsValuationPrice(valuationsPerMonth) +
        annualRentalCarPricingFees(includeRentalCarPricingService, reportsPerMonth) +
        additionalUsersPrice(numberOfUsers) * 12
    );
};

export const basicMonthlyTotal = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        basicAnnualTotal(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService) / 12 +
        MONTHLY_PAYMENT_CYCLE_FEE
    );
};

export const basicFirstYearFee = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        basicOneTimeTotal() +
        basicAnnualTotal(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService)
    );
};

export const basicFirstYearMonthlyFee = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        basicFirstYearFee(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService) / 12 +
        MONTHLY_PAYMENT_CYCLE_FEE
    );
};

/////////////////////////////////////////////////////////////////////////////*/
//  END Basic Price
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Flatrate Price
//****************************************************************************/
export const flatrateAdditionalCalls = (reportsPerMonth: string, valuationsPerMonth: string): number => {
    return Math.max(
        Math.max(parseNumber(reportsPerMonth), parseNumber(valuationsPerMonth)) * 12 - ANNUAL_CALLS_INCLUDED_FLATRATE,
        0,
    );
};

export const flatrateAdditionalCallsPrice = (reportsPerMonth: string, valuationsPerMonth: string): number => {
    // Example: We have 75 calls per month. That's 900 calls per year. To get the number of calls between 751 and 850, we must
    // take all additional calls and take the lower value of that and 100 (in our case that's the lower one of 100 and 150).
    // To get the number of additional calls between 851 and 951, we subtract 100 (since 851 is by 100 higher than 751) and get the lower number
    // of that result (150 - 100 = 50) and 100 (result is 50).
    // Why do we have another Math.max there? In case we have only 800 calls a year (50 additional calls), 50 minus 100 would be a negative number.
    // With Math.max, we assign zero for all negative numbers instead.
    const callPriceFrom751To850 =
        Math.round(Math.min(flatrateAdditionalCalls(reportsPerMonth, valuationsPerMonth), 100)) *
        CALL_PRICE_FLATRATE_FROM_751;
    const callPriceFrom851To950 =
        Math.round(Math.max(Math.min(flatrateAdditionalCalls(reportsPerMonth, valuationsPerMonth) - 100, 100), 0)) *
        CALL_PRICE_FLATRATE_FROM_851;
    const callPriceFrom951To1150 =
        Math.round(Math.max(Math.min(flatrateAdditionalCalls(reportsPerMonth, valuationsPerMonth) - 200, 200), 0)) *
        CALL_PRICE_FLATRATE_FROM_951;
    // The last block has no upper limit, therefore we don't need a Math.min with 100
    const callPriceFrom1151 =
        Math.round(Math.max(flatrateAdditionalCalls(reportsPerMonth, valuationsPerMonth) - 400, 0)) *
        CALL_PRICE_FLATRATE_FROM_1151;
    return callPriceFrom751To850 + callPriceFrom851To950 + callPriceFrom951To1150 + callPriceFrom1151;
};

export const flatrateAnnualTotal = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        vinCallsPrice(reportsPerMonth, valuationsPerMonth) +
        MONTHLY_BASE_FEE_FLATRATE * 12 +
        flatrateAdditionalCallsPrice(reportsPerMonth, valuationsPerMonth) +
        MONTHLY_AZT_PAINT_SYSTEM_FEE * 12 +
        annualRentalCarPricingFees(includeRentalCarPricingService, reportsPerMonth) +
        additionalUsersPrice(numberOfUsers) * 12
    );
};

export const flatrateMonthlyTotal = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        flatrateAnnualTotal(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService) / 12 +
        MONTHLY_PAYMENT_CYCLE_FEE
    );
};

export const flatrateFirstYearAnnualFee = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        flatrateOneTimeTotal() +
        flatrateAnnualTotal(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService)
    );
};

export const flatrateFirstYearMonthlyFee = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
): number => {
    return (
        flatrateFirstYearAnnualFee(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService) /
            12 +
        MONTHLY_PAYMENT_CYCLE_FEE
    );
};

export const lowestApplicableCalculationPrice = (reportsPerMonth: string): number => {
    const reportsPerYear = parseNumber(reportsPerMonth) * 12;
    if (reportsPerYear > 1150) {
        return CALL_PRICE_FLATRATE_FROM_1151;
    }
    if (reportsPerYear > 950) {
        return CALL_PRICE_FLATRATE_FROM_951;
    }
    if (reportsPerYear > 850) {
        return CALL_PRICE_FLATRATE_FROM_851;
    }
    return CALL_PRICE_FLATRATE_FROM_751;
};

/////////////////////////////////////////////////////////////////////////////*/
//  END Flatrate Price
/////////////////////////////////////////////////////////////////////////////*/

/////////////////////////////////////////////////////////////////////////////*/
//  END Usage Prices
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Number Of Users
//****************************************************************************/
export const additionalUsers = (numberOfUsers: number): number => {
    // 2 Users are included
    return Math.max(0, numberOfUsers - USERS_INCLUDED);
};

// Annual price
export const additionalUsersPrice = (numberOfUsers: number): number => {
    return additionalUsers(numberOfUsers) * ADDITIONAL_USERS_FEE;
};

/////////////////////////////////////////////////////////////////////////////*/
//  END Number Of Users
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Lowest Fee / Best Price
/////////////////////////////////////////////////////////////////////////////*/
export const lowestFirstYearFee = (
    numberOfUsers: number,
    reportsPerMonth: string,
    valuationsPerMonth: string,
    includeRentalCarPricingService: boolean,
    alreadyDatCustomer: boolean,
): number => {
    const availablePlans = [
        basicFirstYearFee(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService),
        flatrateFirstYearAnnualFee(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService),
    ];

    if (perCasePlanAvailable(alreadyDatCustomer, reportsPerMonth, valuationsPerMonth)) {
        availablePlans.push(
            perCaseFirstYearFee(numberOfUsers, reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService),
        );
    }

    if (starterPlanAvailable(alreadyDatCustomer, reportsPerMonth, valuationsPerMonth)) {
        availablePlans.push(starterFirstYearFee(reportsPerMonth, valuationsPerMonth, includeRentalCarPricingService));
    }
    return Math.min(...availablePlans);
};

/////////////////////////////////////////////////////////////////////////////*/
//  END Lowest Fee / Best Price
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Starter Package Equivalent Value
//****************************************************************************/
/**
 * How much percent does the user save if he orders the starter package compared to the basic package?
 */
export const starterPackageEquivalentValue = () => {
    return (
        ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION * CALL_PRICE_STARTER_CALCULATION +
        ANNUAL_CALLS_INCLUDED_STARTER_VALUATION * CALL_PRICE_STARTER_VALUATION +
        DAT_LICENSE +
        DAT_SETUP_AND_INSTRUCTION
    );
};

export const starterPackageSavingsInPercent = () => {
    return (starterPackageEquivalentValue() - STARTER_SETUP_FEE) / starterPackageEquivalentValue();
};
