@for (instructor of instructors; track instructor.name) {
    <section class="instructor-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="instructor-photo-wrapper col-sm col-md-4">
                    <img
                        class="instructor-photo"
                        src="{{ instructor.imagePath }}"
                        alt="{{ instructor.altDescription }}" />
                </div>
                <div class="col-sm col-md-4">
                    <h3 class="additional-headline">
                        @if (isOnlineExchange) {
                            {{ instructors.length > 1 ? 'Dein Moderatoren-Team' : 'Dein Moderator' }}
                        } @else {
                            {{ instructors.length > 1 ? 'Dein Referenten-Team' : 'Dein Referent' }}
                        }
                    </h3>
                    <h2>{{ instructor.displayName || instructor.name }}</h2>
                    @for (description of instructor.description; track description) {
                        <p>{{ description }}</p>
                    }
                </div>
            </div>
        </div>
    </section>
}
