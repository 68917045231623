<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Die Restwertermittlung im Gutachten eines KFZ-Sachverständigen muss immer auf Grundlage des aktuellen Standes
        der Rechtsprechung erfolgen. Wie ist der Restwert aktuell zu ermitteln? Welche Möglichkeiten der
        Restwertermittlung gibt es? Wie ist mit der Restwertermittlung bei exotischen Fahrzeugen umzugehen? Diese Fragen
        werden im Seminar beantwortet.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die Grundlagen zur Restwertermittlung erwerben oder vertiefen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
