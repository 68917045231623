<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        In diesem Webinar erhalten Sie einen kompakten Überblick über die Erstellung von Wertgutachten für Oldtimer.
        Zunächst werden die verschiedenen Formen von Oldtimer-Bewertungen, deren korrekte Anwendung und Abgrenzung zur
        klassischen Gebrauchtwagenbewertung vorgestellt. Weiter erfolgt eine Einführung in das Zustandsnotensystem,
        sowie die wesentlichen Wertermittlungsfaktoren bei der Bewertung klassischer Fahrzeuge.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Kfz-Sachverständige, die die Grundlagen der qualifizierten Oldtimer-Bewertung kennenlernen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
