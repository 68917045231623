import { inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    protected readonly platformId = inject(PLATFORM_ID);

    constructor(@Inject(DOCUMENT) private document: Document) {}

    setCanonicalUrl(pageName?: string): void {
        if (isPlatformServer(this.platformId)) {
            this.removeTag("link[rel='canonical']");

            const path = pageName ?? this.document.URL;
            const canonicalUrl = `https://www.autoixpert.de${path}`;

            const link: HTMLLinkElement = this.document.createElement('link');
            link.setAttribute('rel', 'canonical');
            this.document.head.appendChild(link);
            link.setAttribute('href', canonicalUrl);
        }
    }

    setNoIndex(): void {
        this.removeTag("meta[name='robots']");
        const metaElem: HTMLMetaElement = this.document.createElement('meta');
        metaElem.setAttribute('name', 'robots');
        metaElem.setAttribute('content', 'noindex');
        this.document.head.appendChild(metaElem);
    }

    private removeTag(selector: string) {
        const elements = this.document.querySelectorAll(selector);
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            element.remove();
        }
    }
}
