import { Component, inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import moment from 'moment';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { getParameterByName } from '../../../shared/lib/get-parameters-by-name';
import { scrollElementIntoView } from '../../../shared/lib/scroll-element-into-view';
import { isPlatformBrowser, NgIf } from '@angular/common';
import { EMAIL_VALIDATION_REGEX } from '../../../shared/lib/email-validation-regex';

@Component({
    selector: 'ax-produkt-webinar',
    standalone: true,
    imports: [FormsModule, HttpClientModule, ReactiveFormsModule, NgIf],
    templateUrl: './produkt-webinar.component.html',
    styleUrl: './produkt-webinar.component.scss',
})
export class ProduktWebinarComponent {
    protected emailValidationRegex = EMAIL_VALIDATION_REGEX;

    @ViewChild('emailInput') emailInputModel!: NgModel;

    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);
    protected readonly httpClient = inject(HttpClient);
    protected readonly platformId = inject(PLATFORM_ID);

    protected webinarDate: string | null = null;
    protected organization = null;
    protected firstName = null;
    protected lastName = null;
    protected email = null;
    protected signupPending = false;
    protected signupSuccessful = false;
    protected eventOverbooked = false;
    protected duplicate = false;

    constructor() {
        this.titleService.setTitle('Webinar');
        this.metaService.addTag({
            name: 'description',
            content: 'Erlebe die Kfz-Sachverständigen-Software autoiXpert jeden Monat live.',
        });
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.webinarDate = atob(getParameterByName('webinarDate') ?? '');
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  autoiXpert Video Player
    //****************************************************************************/
    protected openAutoixpertVideo() {
        const heroautoiXpertVideoPlayerSection = $('#hero-video-player-section');

        $(
            '<iframe id="hero-video" width="80%" height="100%" src="https://www.youtube.com/embed/dvl-uWl2NtI?rel=0&showinfo=0&modestbranding=1&autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>',
        ).appendTo(heroautoiXpertVideoPlayerSection);

        heroautoiXpertVideoPlayerSection.show();

        window.addEventListener('keydown', this.closeVideoPlayerWithEscKey);
    }

    protected closeVideoPlayer() {
        const heroVideoPlayerSection = $('#hero-video-player-section');
        $('#hero-video').remove();

        heroVideoPlayerSection.hide();

        window.removeEventListener('keydown', this.closeVideoPlayerWithEscKey);
    }

    protected closeVideoPlayerWithEscKey(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.closeVideoPlayer();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END autoiXpert Video Player
    /////////////////////////////////////////////////////////////////////////////*/

    protected sendEventSignupForm(webinarDate: string | null) {
        // Don't allow empty forms.
        if (!this.organization || !this.firstName || !this.lastName || !this.email) {
            return;
        }

        // Don't allow double signups
        if (this.signupPending) return;

        this.signupPending = true;
        this.signupSuccessful = false;
        this.eventOverbooked = false;
        this.duplicate = false;

        this.httpClient
            .post(
                '/api/v0/webinarParticipants',
                JSON.stringify({
                    organization: this.organization,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    webinarDate: moment.utc(webinarDate).format(),
                    type: 'axWebinar',
                    title: 'Produkt-Webinar',
                }),
                {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
            )
            .subscribe({
                next: (data: any) => {
                    if (data.success) {
                        this.signupSuccessful = true;
                    }
                    if (data.eventOverbooked) {
                        this.eventOverbooked = true;
                    }
                    if (data.duplicate) {
                        this.duplicate = true;
                    }
                },
                complete: () => {
                    this.signupPending = false;
                },
            });
    }

    protected showSignupForm() {
        // this.organization     = null; It's likely a second person is coming from the same office.
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.signupSuccessful = false;
        this.eventOverbooked = false;
        this.duplicate = false;

        // Reset the ngModel, otherwise it will stay touched and display an error message because its invalid (empty)
        this.emailInputModel.reset();
    }

    protected getWebinarDateLongForm() {
        if (!this.webinarDate) return 'lädt...';
        return moment(this.webinarDate).format('DD. MMMM YYYY');
    }

    protected getWebinarDateLongFormWithWeekday() {
        if (!this.webinarDate) return 'lädt...';
        return moment(this.webinarDate).format('dddd, DD. MMMM YYYY');
    }

    protected getWebinarTime(minutesOffset: number = 0) {
        if (!this.webinarDate) return 'lädt...';
        return moment(this.webinarDate).add(minutesOffset, 'minutes').format('HH:mm');
    }

    protected disallowed() {
        return !this.organization || !this.firstName || !this.lastName || !this.email || this.emailInputModel.invalid;
    }

    protected scrollElemIntoView = scrollElementIntoView;
}
