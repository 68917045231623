<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Ein Kaskoschadengutachten erfordert im Vergleich zur Erstellung eines Haftpflichtschadengutachtens eine genaue
        Kenntnis der Vertragssituation zwischen Kunde und Versicherung. Das Gutachten selbst ist inhaltlich in
        wesentlichen Punkten an die entsprechende vertragliche Situation anzupassen. In diesem Teil 2 des Seminars
        werden die Grundlagen aus Teil 1 in ein praktisches Kaskoschadengutachten umgesetzt.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die Grundlagen zum Schreiben von Kaskogutachten erwerben möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
