import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SENIOR_DEV_STRUCTURED_DATA } from './senior-developer.google-structured-data';
import { GoogleStructuredDataComponent } from '../../../shared/components/google-structured-data.component';

@Component({
    selector: 'ax-senior-developer',
    standalone: true,
    imports: [GoogleStructuredDataComponent],
    templateUrl: './senior-developer.component.html',
    styleUrl: '../job-details.scss',
})
export class SeniorDeveloperComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    protected googleStructuredData = SENIOR_DEV_STRUCTURED_DATA;

    constructor() {
        this.titleService.setTitle('Senior Angular Developer | Jobs');
        this.metaService.addTag({
            name: 'description',
            content:
                'Werde als Senior Developer für Angular, Node.js & TypeScript Teil unseres Teams und arbeite an der führenden Software für Kfz-Gutachter.',
        });
    }
}
