import {Pipe, PipeTransform} from '@angular/core';
import {formatPrice} from '../lib/price-calculation/price-calculator-utils';

/**
 * A pipe wrapping the formatPrice function, which formats a given number as a price in Euro.
 * E.g. 1000 -> 1.000,00 €
 */
@Pipe({
    name: 'formatPrice',
    standalone: true,
})
export class FormatPricePipe implements PipeTransform {
    transform(price: number, fractionDigits?: number): unknown {
        return formatPrice(price, fractionDigits);
    }
}
