import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DEVELOPER_STRUCTURED_DATA } from './developer.google-structured-data';
import { GoogleStructuredDataComponent } from '../../../shared/components/google-structured-data.component';

@Component({
    selector: 'ax-developer',
    standalone: true,
    imports: [GoogleStructuredDataComponent],
    templateUrl: './developer.component.html',
    styleUrl: '../job-details.scss',
})
export class DeveloperComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    protected googleStructuredData = DEVELOPER_STRUCTURED_DATA;

    constructor() {
        this.titleService.setTitle('Angular Developer | Jobs');
        this.metaService.addTag({
            name: 'description',
            content:
                'Bist du smart und hast schon 2 Jahre Development-Erfahrung? Bring deine Karriere mit autoiXpert auf die nächste Stufe und arbeite an der führenden Software für Kfz-Gutachter.',
        });
    }
}
