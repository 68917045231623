import { Component, inject, Input, ViewChild } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { GetWebinarDateLongFormWithWeekdayPipe } from '../../pipes/get-webinar-date-long-form-with-weekday.pipe';
import { GetWebinarTimePipe } from '../../pipes/get-webinar-time.pipe';
import moment from 'moment';
import { WebinarContent } from '../webinar-agenda/webinar-agenda.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgIf } from '@angular/common';
import { EMAIL_VALIDATION_REGEX } from '../../../../shared/lib/email-validation-regex';

@Component({
    selector: 'ax-webinar-signup-section',
    standalone: true,
    imports: [FormsModule, GetWebinarDateLongFormWithWeekdayPipe, GetWebinarTimePipe, HttpClientModule, NgIf],
    templateUrl: './webinar-signup-section.component.html',
    styleUrl: './webinar-signup-section.component.scss',
})
export class WebinarSignupSectionComponent {
    protected emailValidationRegex = EMAIL_VALIDATION_REGEX;

    @ViewChild('emailInput') emailInputModel!: NgModel;
    protected readonly httpClient = inject(HttpClient);

    @Input() webinarDate: string | null = null;
    @Input() duration: number = 0;
    @Input() price: number = 0;
    @Input() webinarTitle: string = '';
    @Input() instructor: string = '';
    @Input() eventOverbooked: boolean = false;
    @Input() webinarContents: Array<WebinarContent> = [];
    @Input() isOnlineExchange: boolean = false;

    protected organization: string | null = null;
    protected firstName: string | null = null;
    protected lastName: string | null = null;
    protected street: string | null = null;
    protected zip: string | null = null;
    protected city: string | null = null;
    protected email: string | null = null;
    protected signupPending = false;
    protected signupSuccessful = false;
    protected duplicate = false;

    protected sendEventSignupForm(webinarDate: string | null) {
        // Don't allow empty forms.
        if (!this.organization || !this.firstName || !this.lastName || !this.email) {
            return;
        }

        // Don't allow double signups
        if (this.signupPending) return;

        this.signupPending = true;
        this.signupSuccessful = false;
        this.eventOverbooked = false;
        this.duplicate = false;

        this.httpClient
            .post(
                '/api/v0/webinarParticipants',
                JSON.stringify({
                    organization: this.organization,
                    firstName: this.firstName.trim(),
                    lastName: this.lastName.trim(),
                    street: this.street,
                    zip: this.zip,
                    city: this.city,
                    email: this.email.trim(),
                    webinarDate: moment.utc(webinarDate).format(),
                    title: this.webinarTitle,
                    type: 'expertWebinar',
                    duration: this.duration,
                    instructor: this.instructor,
                    // Strip the optional link. That doesn't have to be stored in the database.
                    webinarContents: this.webinarContents.map((contentItem) => ({
                        title: contentItem.title,
                        description: contentItem.description,
                    })),
                    billing: {
                        webinarPriceNet: this.price,
                    },
                }),
                {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
            )
            .subscribe({
                next: (data: any) => {
                    if (data.success) {
                        this.signupSuccessful = true;
                    }
                    if (data.eventOverbooked) {
                        this.eventOverbooked = true;
                    }
                    if (data.duplicate) {
                        this.duplicate = true;
                    }
                },
                error: (error) => {
                    alert(error.message);
                    console.error(error);
                    this.signupPending = false;
                },
                complete: () => {
                    this.signupPending = false;
                },
            });
    }

    protected showSignupForm() {
        // this.organization     = null; It's likely a second person is coming from the same office.
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.signupSuccessful = false;
        this.eventOverbooked = false;
        this.duplicate = false;

        // Reset the ngModel, otherwise it will stay touched and display an error message because its invalid (empty)
        this.emailInputModel.reset();
    }

    protected disallowed() {
        const personalDataComplete =
            (this.organization || '').trim() &&
            (this.firstName || '').trim() &&
            (this.lastName || '').trim() &&
            (this.email || '').trim() &&
            this.emailInputModel.valid;
        const invoicingDataComplete = (this.street || '').trim() && (this.zip || '').trim() && (this.city || '').trim();

        if (this.price) {
            return !personalDataComplete || !invoicingDataComplete;
        } else {
            return !personalDataComplete;
        }
    }
}
