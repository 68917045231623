import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';

@Pipe({
    name: 'getWebinarTime',
    standalone: true,
})
export class GetWebinarTimePipe implements PipeTransform {
    transform(webinarDate: string | null, minutesOffset: number = 0): unknown {
        if (!webinarDate) return 'lädt...';
        return moment(webinarDate).add(minutesOffset, 'minutes').format('HH:mm');
    }
}
