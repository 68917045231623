import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';

@Pipe({
    name: 'eventDate',
    standalone: true,
})
export class EventDatePipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        return moment(value).format('dd, DD.MM. - HH:mm [Uhr]').replace(':00', '');
    }
}
