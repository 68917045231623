<!--============================================-->
<!-- Search Helpcenter -->
<!--============================================-->
<section id="search-helpcenter-section">
    <div class="container">
        <!--********** Headline **********-->
        <div class="row text-center justify-content-center">
            <div class="col">
                <h3 class="additional-headline">Die größte Wissensdatenbank für Kfz-Gutachter</h3>
                <h2>Der beste Support hilft rund um die Uhr</h2>
                <a id="sales-hotline" href="/kontakt#contact-section">
                    <i class="material-icons">person</i>
                    Kontakt zum Vertrieb
                </a>
            </div>
        </div>

        <!--********** Search Field **********-->
        <div class="row">
            <div class="col-12">
                <div id="search-field-container">
                    <input
                        class="newsletter-input"
                        type="text"
                        [(ngModel)]="searchTerm"
                        (input)="fetchArticlesWithDelay()"
                        placeholder='Gib ein Thema ein, z. B. "Zugangsdaten DAT hinterlegen"' />
                    @if (articleSearchPending) {
                        <img class="search-input-prefix" src="/assets/images/loading-indicator.svg" alt="" />
                    } @else {
                        <i class="search-input-prefix material-icons">search</i>
                    }
                </div>
            </div>
        </div>

        <!--********** Search Suggestions **********-->
        @if (articleSearchResults) {
            <!--============================================-->
            <!-- Search Results -->
            <!--============================================-->
            <div class="row">
                <div class="col-12">
                    <div id="article-search-result-list">
                        @for (searchResult of articleSearchResults; track searchResult.html_url) {
                            <div class="search-result-item">
                                <a [href]="searchResult.html_url" target="_blank" rel="”noopener”">
                                    {{ searchResult.title }}
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Search Results -->
            <!--============================================-->
        } @else {
            <div class="row">
                <div class="col-12">
                    <h3 id="search-suggestions-heading">Beliebte Wissens-Artikel</h3>
                    <div id="search-suggestions-container">
                        @for (searchSuggestion of searchSuggestions; track searchSuggestion.label) {
                            <a class="search-suggestion" [href]="searchSuggestion.url" target="_blank" rel="noopener">
                                {{ searchSuggestion.label }}
                            </a>
                        }
                    </div>
                </div>
            </div>
        }
    </div>
</section>
<!--============================================-->
<!-- END Search Helpcenter -->
<!--============================================-->

<!--============================================-->
<!-- Get in touch -->
<!--============================================-->
<section id="contact-section">
    <div class="container">
        <div class="row text-center justify-content-center mb-5">
            <div class="col">
                <h3 class="additional-headline">Der direkte Draht zu uns</h3>
                <h2>Fragen? Kontaktiere uns.</h2>
            </div>
        </div>
        <div class="row justify-content-between">
            <!--============================================-->
            <!-- Contact Details autoiXpert -->
            <!--============================================-->
            <div class="col-lg-4 mb-5 text-center text-lg-left">
                <div id="contact-details-and-image-container">
                    <img id="contact-image" src="/assets/images/x-on-grey-background.png" alt="autoiXpert Logo" />
                    <div id="contact-details-container">
                        <h3 class="contact-details-heading">autoiXpert</h3>
                        <div>+49 8222 91 88 99 0</div>
                        <div class="working-hours">Mo - Fr von 9 - 17 Uhr</div>
                        <div class="email-address">
                            <a href="mailto:info@autoixpert.de">info&#64;autoixpert.de</a>
                        </div>

                        <!--********** Anydesk **********-->
                        <div id="anydesk-container">
                            <div id="anydesk-heading">
                                <strong>Fragen zu Technik oder Bedienung?</strong>
                            </div>
                            <p>Starte bitte Anydesk und rufe an. So können wir dir schnell weiterhelfen.</p>
                            <a href="https://anydesk.com/de/downloads" target="_blank" rel="noopener">
                                <img
                                    src="/assets/images/fernwartung-per-anydesk.png"
                                    alt="Fernwartung per Anydesk (Logo)" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Contact Details autoiXpert -->
            <!--============================================-->

            <div class="col-lg-4 mb-5 text-center text-lg-left">
                <!--============================================-->
                <!-- Contact Details DAT -->
                <!--============================================-->
                <div id="dat-contact-details-and-image-container">
                    <img id="dat-logo" src="/assets/images/logos/dat-logo-no-text.svg" alt="DAT Logo" />

                    <div>
                        <h3 class="contact-details-heading">Alles rund um DAT</h3>
                        <div>+49 711 4503 140</div>
                        <div class="working-hours">Mo - Fr von 8 - 18 Uhr</div>
                        <div class="email-address">
                            <a href="mailto:kundendienst@dat.de">kundendienst&#64;dat.de</a>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Contact Details DAT -->
                <!--============================================-->
                <!--***********************************************
        ** Contact Details Audatex
        ************************************************-->
                <div id="audatex-contact-details-and-image-container">
                    <img id="audatex-logo" src="/assets/images/logos/audatex.svg" alt="Audatex Logo" />

                    <div>
                        <h3 class="contact-details-heading">Kontakt zu Audatex</h3>
                        <div>+49 (0) 30 209 691 01</div>
                        <div class="working-hours">Mo - Fr von 9 - 17 Uhr</div>
                        <div class="email-address">
                            <a href="mailto:info@ax-ao.de">info&#64;ax-ao.de</a>
                        </div>
                    </div>
                </div>
                <!--***********************************************
        ** END Contact Details Audatex
        ************************************************-->
            </div>

            <div class="col-lg-4 mb-5 text-center text-lg-left">
                <h3 class="contact-details-heading">Firmensitz Bayern</h3>
                Binsentalstr. 40A
                <br />
                89331 Burgau (Bayern)
                <br />
                <a
                    href="https://www.google.de/maps/search/autoixpert"
                    target="_blank"
                    rel="noopener"
                    class="google-maps-link">
                    In Google Maps öffnen
                </a>

                <h3 class="contact-details-heading">Büro Paderborn</h3>
                Im
                <a href="https://www.businesspark1792.de/">Businesspark 1792</a>
                <br />
                Am Ehrenmal 2
                <br />
                33178 Borchen (Paderborn)
                <br />
                <a
                    href="https://www.google.com/maps/place/Am+Ehrenmal+2,+33178+Borchen/data=!4m2!3m1!1s0x47bbb47cbab30db5:0x339b67f18362fdde?sa=X&ved=2ahUKEwjTk-HZtOTxAhWf_7sIHV9wDPUQ8gEwAHoECAoQAQ"
                    target="_blank"
                    rel="noopener"
                    class="google-maps-link">
                    In Google Maps öffnen
                </a>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Get in touch -->
<!--============================================-->

<!-- Google structured data. See https://developers.google.com/search/docs/guides/intro-structured-data -->
<script type="application/ld+json">
    {
        "@context": "http://schema.org",
        "@type": "Organization",
        "url": "https://www.autoixpert.de/Kontakt",
        "name": "autoiXpert GmbH & Co. KG",
        "contactPoint": [
            {
                "@type": "ContactPoint",
                "telephone": "+49 8222 91 88 99 0",
                "email": "info@autoixpert.de",
                "contactType": "customer service"
            }
        ]
    }
</script>
