<section id="founders-section">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col text-center">
                <h3 class="additional-headline">Das Team</h3>
                <h2>Gründer, Zuhörer & Macher</h2>
            </div>
        </div>
        <div class="row justify-content-center">
            <!--********** Steffen **********-->
            <div class="col-md-4">
                <div class="founder-container">
                    <!--********** Photo **********-->
                    <div class="founder-image-container">
                        <img src="/assets/images/people/steffen-sommer.jpg" alt="Bild von Steffen Langer" />
                        <!--                        <img src="/assets/images/people/steffen.jpg" alt="Steffen Langer">-->
                    </div>

                    <!--********** Name, Title & Description **********-->
                    <h3 class="founder-name">Steffen Langer</h3>
                    <h4 class="founder-title">Gesellschafter</h4>
                    <p class="founder-focus">Software Engineering & HR</p>
                    <p class="dream-car">
                        Traumauto:
                        <a href="https://www.google.com/search?q=Mercedes+AMG+SL" target="_blank" rel="noopener">
                            Mercedes AMG SL
                        </a>
                    </p>
                </div>
            </div>

            <!--********** Mark **********-->
            <div class="col-md-4">
                <div class="founder-container">
                    <!--********** Photo **********-->
                    <div class="founder-image-container">
                        <img src="/assets/images/people/mark-sommer.jpg" alt="Bild von Mark Langer" />
                        <!--                        <img src="/assets/images/people/mark.jpg" alt="Mark Langer">-->
                    </div>

                    <!--********** Name, Title & Description **********-->
                    <h3 class="founder-name">Mark Langer</h3>
                    <h4 class="founder-title">Gesellschafter</h4>
                    <p class="founder-focus">Software Design & Marketing</p>
                    <p class="dream-car">
                        Traumauto:
                        <a href="https://www.google.com/search?q=Mercedes-Benz+AMG+GT" target="_blank" rel="noopener">
                            Mercedes-Benz AMG GT
                        </a>
                    </p>
                </div>
            </div>

            <!--********** Andi **********-->
            <div class="col-md-4">
                <div class="founder-container">
                    <!--********** Photo **********-->
                    <div class="founder-image-container">
                        <img src="/assets/images/people/andreas-sommer.jpg" alt="Bild von Andreas Schliefer" />
                        <!--                        <img src="/assets/images/people/andreas.jpg" alt="Andreas Schliefer">-->
                    </div>

                    <!--********** Name, Title & Description **********-->
                    <h3 class="founder-name">Andreas Schliefer</h3>
                    <h4 class="founder-title">Gesellschafter</h4>
                    <p class="founder-focus">Vertrieb & Finanzen</p>
                    <p class="dream-car">
                        Traumauto:
                        <a href="https://www.google.com/search?q=porsche+918+spyder" target="_blank" rel="noopener">
                            Porsche 918 Spyder
                        </a>
                    </p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <!--********** Olga **********-->
            <div class="col-md-4">
                <div class="founder-container">
                    <!--********** Photo **********-->
                    <div class="founder-image-container">
                        <img src="/assets/images/people/olga_300.jpg" alt="Bild von Olga Hirth" />
                    </div>

                    <!--********** Name, Title & Description **********-->
                    <h3 class="founder-name">Olga Hirth</h3>
                    <p class="founder-focus">Customer Support</p>
                    <p class="dream-car">
                        Traumauto:
                        <a href="https://www.google.com/search?q=aston+martin+db5" target="_blank" rel="noopener">
                            Aston Martin DB5
                        </a>
                    </p>
                </div>
            </div>

            <!--********** Sonja **********-->
            <!--            <div class="col-md-4">-->
            <!--                <div class="founder-container">-->
            <!--                    &lt;!&ndash;********** Photo **********&ndash;&gt;-->
            <!--                    <div class="founder-image-container">-->
            <!--                        <img src="/assets/images/people/sonja.jpg">-->
            <!--                    </div>-->

            <!--                    &lt;!&ndash;********** Name, Title & Description **********&ndash;&gt;-->
            <!--                    <h3 class="founder-name">Sonja Coors</h3>-->
            <!--                    <p class="founder-focus">Business Development</p>-->
            <!--                    <p class="dream-car">Traumauto: <a href="https://www.google.com/search?q=cabrio+rot+bmw+m8"-->
            <!--                                                       target="_blank" rel="noopener">BMW M8 Cabrio</a></p>-->
            <!--                </div>-->
            <!--            </div>-->

            <!-- ********** Lukas ********** -->
            <div class="col-md-4">
                <div class="founder-container">
                    <!--********** Photo **********-->
                    <div class="founder-image-container">
                        <img alt="Bild von Lukas Großmann" src="/assets/images/people/lukas_300.jpg" />
                    </div>

                    <!--********** Name, Title & Description **********-->
                    <h3 class="founder-name">Lukas Großmann</h3>
                    <p class="founder-focus">Software Engineering</p>
                    <p class="dream-car">
                        Traumauto:
                        <a href="https://www.google.com/search?q=Mercedes-AMG+G+63" target="_blank" rel="noopener">
                            Mercedes-AMG G 63
                        </a>
                    </p>
                </div>
            </div>

            <!-- ********** Sven ********** -->
            <div class="col-md-4">
                <div class="founder-container">
                    <!--********** Photo **********-->
                    <div class="founder-image-container">
                        <img alt="Bild von Sven Fackert" src="/assets/images/people/sven_300.jpg" />
                    </div>

                    <!--********** Name, Title & Description **********-->
                    <h3 class="founder-name">Sven Fackert</h3>
                    <p class="founder-focus">Software Engineering</p>
                    <p class="dream-car">
                        Traumauto:
                        <a
                            href="https://www.google.com/search?q=Mustang+1967+Shelby+GT500"
                            target="_blank"
                            rel="noopener">
                            Mustang 1967 Shelby GT500
                        </a>
                    </p>
                </div>
            </div>

            <!-- ********** Ben ********** -->
            <div class="col-md-4">
                <div class="founder-container">
                    <!--********** Photo **********-->
                    <div class="founder-image-container">
                        <img alt="Bild von Benjamin Assadsolimani" src="/assets/images/people/ben_300.jpg" />
                    </div>

                    <!--********** Name, Title & Description **********-->
                    <h3 class="founder-name">Benjamin Assadsolimani</h3>
                    <p class="founder-focus">Software Engineering</p>
                    <p class="dream-car">
                        Traumauto:
                        <a
                            href="https://www.google.com/search?q=1967er+Chevrolet+Impala"
                            target="_blank"
                            rel="noopener">
                            1967er Chevrolet Impala
                        </a>
                    </p>
                </div>
            </div>

            <!-- ********** Sandra ********** -->
            <div class="col-md-4">
                <div class="founder-container">
                    <!--********** Photo **********-->
                    <div class="founder-image-container">
                        <img alt="Bild von Sandra Bader" src="/assets/images/people/sandra_300.jpg" />
                    </div>

                    <!--********** Name, Title & Description **********-->
                    <h3 class="founder-name">Sandra Bader</h3>
                    <p class="founder-focus">Events & Buchhaltung</p>
                    <p class="dream-car">
                        Traumauto:
                        <a href="https://www.google.com/search?q=Porsche+911+Cabrio" target="_blank" rel="noopener">
                            Porsche 911 Cabrio
                        </a>
                    </p>
                </div>
            </div>

            <!-- ********** Kathi ********** -->
            <div class="col-md-4">
                <div class="founder-container">
                    <!--********** Photo **********-->
                    <div class="founder-image-container">
                        <img alt="Bild von Kathrin Schliefer" src="/assets/images/people/kathi.jpg" />
                    </div>

                    <!--********** Name, Title & Description **********-->
                    <h3 class="founder-name">Kathrin Schliefer</h3>
                    <p class="founder-focus">Events & Marketing</p>
                    <p class="dream-car">
                        Traumauto:
                        <a href="https://www.google.com/search?q=audi+rs6" target="_blank" rel="noopener">Audi RS6</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="story-section">
    <div class="container text-center">
        <div class="row justify-content-center">
            <div class="col-md-7">
                <h3 class="additional-headline">Gute Software ist gut fürs Geschäft</h3>
                <h2>Die Software für Deutschlands erfolgreichste Kfz-Gutachter</h2>
                <p id="story-intro">
                    Zu oft entscheiden die Programmierer, wie Software auszusehen hat und wie du sie bedienen musst.
                </p>
                <p>
                    Wir drehen den Prozess um: Unsere Prozessanalysten analysieren regelmäßig die Arbeitsweise von
                    Kfz-Sachverständigen und entwerfen mit unseren Designern die passenden Lösungen dazu. Unsere
                    hochspezialisierten Software-Architekten & -Ingenieure haben auf dieser Basis das digitale Werkzeug
                    geschaffen, das Kfz-Gutachter jeden Tag an die Spitze ihrer Branche bringt: autoiXpert.
                </p>
                <p>
                    In ganz Deutschland vertrauen über 2.000 Kfz-Sachverständige auf autoiXpert. Sie erstellen
                    Gutachten, rechnen ihre Honorare ab und werten mit grafischen Analyse-Tools den wachsenden Erfolg
                    ihres Unternehmens aus. Wann startest du mit autoiXpert?
                </p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col">
                <img id="treasure-map" src="/assets/images/treasure-map.svg" alt="Schatzkarte - Weg der Gründung" />
            </div>
        </div>
    </div>
</section>

<section id="primexperts-section">
    <div class="container text-center">
        <div class="row justify-content-center">
            <div class="col-md-7">
                <h3 class="additional-headline">Hand in Hand mit Fachexperten</h3>
                <h2>Der direkte Draht zum Markt</h2>
                <!-- prettier-ignore -->
                <p>
                    Design, Technik & Prozessverständnis sind die wichtigsten Bausteine
                    für moderne Software.
                    <br class="d-none d-md-block"/>
                    Das Technik- & Design-Know-How unseres Teams ergänzen die aktuell 15
                    Sachverständigen
                    <br class="d-none d-md-block"/>
                    in unserem
                    <strong>primeXperts-Programm mit ihrer fachlichen Expertise</strong>.
                    <br/>
                </p>

                <h4 id="prototyping">Ideen & Prototyping</h4>
                <!-- prettier-ignore -->
                <p>
                    In einem freundschaftlichen Verhältnis können Ideen wachsen. Für Ideen
                    mit besonderem Potenzial erarbeiten wir grafische Prototypen, die wir
                    mit unseren Partnern testen und gemeinsam optimieren. Sobald der
                    Prototyp das Praxis-Siegel der primeXperts
                    <br class="d-none d-md-block"/>
                    erhalten hat, geht er in die Umsetzung. Das Feature kommt dann
                    <br class="d-none d-md-block"/>
                    <strong>zeitnah allen Nutzern zugute</strong>.
                </p>
            </div>
        </div>
    </div>
</section>
