<section id="pricing-intro-section">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-md-7">
                <h3 class="additional-headline">Preise GT Estimate</h3>
                <h1 id="pricing-intro-headline">
                    Was kostet GT Motive
                    <br class="d-none d-md-block" />
                    für Kfz-Sachverständige?
                </h1>
                <p id="pricing-intro-tagline">
                    Die Kalkulationssoftware GT Estimate von GT Motive erlaubt Kfz-Sachverständigen
                    <br class="d-none d-md-block" />
                    die Kalkulation von Fahrzeugschäden mit einer modernen Nutzeroberfläche
                    <br class="d-none d-md-block" />
                    zu günstigen Preisen.
                </p>
            </div>
        </div>
    </div>
</section>

<div>
    <!--============================================-->
    <!-- Price Calculator -->
    <!--============================================-->
    <section id="plans-section">
        <div class="container">
            <div class="subscription-plans-container row justify-content-center">
                <div class="col-md-3 mb-5 mb-md-0">
                    <!--============================================-->
                    <!-- Calculator Inputs -->
                    <!--============================================-->
                    <div id="price-calculator-inputs-wrapper">
                        <a
                            id="price-calculator-gtmotive-logo"
                            href="https://gtmotive.com/de/produkte/gt-estimate/"
                            target="_blank"
                            rel="noopener">
                            <img src="/assets/images/logos/gtmotive-logo-without-text.png" alt="Logo GT Motive" />
                        </a>
                        <div id="price-calculator-inputs-container">
                            <div class="price-calculator-input-container">
                                <label for="reports-per-month">Kalkulationen pro Monat</label>
                                <input
                                    type="text"
                                    id="reports-per-month"
                                    [(ngModel)]="reportsPerMonth"
                                    placeholder="Bitte ausfüllen"
                                    (input)="initializeTooltips()"
                                    (keydown)="handleArrowUpAndDownKeys($event, 'reportsPerMonth')" />
                            </div>

                            <div id="in-cooperation-with-autoixpert-container">
                                in Kooperation mit
                                <a href="/">
                                    <img
                                        id="in-cooperation-with-autoixpert-logo"
                                        src="/assets/images/logo-autoixpert-invertiert-blaues-x.svg"
                                        alt="autoiXpert-Logo Software für Sachverständige & Gutachter" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Calculator Inputs -->
                    <!--============================================-->
                </div>

                @if (!calculationInputsComplete()) {
                    <div class="col-md d-flex align-items-center justify-content-center text-center">
                        <div class="gtmotive-plan-container">
                            <h3>
                                Berechne die Kosten für deine
                                <br class="d-none d-md-block" />
                                Software von GT Motive
                            </h3>
                            <p>Fülle alle Felder im blauen Kasten aus.</p>
                        </div>
                    </div>
                }
                @if (calculationInputsComplete()) {
                    <!--============================================-->
                    <!-- Flex Plan -->
                    <!--============================================-->
                    <div class="col-md">
                        <div class="gtmotive-plan-container">
                            <h2 class="gtmotive-plan-title">Einzelabrufe</h2>
                            <div class="gtmotive-plan-subtitle label">Grundgebühr + flexible Abrufe</div>
                            <!--============================================-->
                            <!-- Plan Items -->
                            <!--============================================-->
                            <div class="gtmotive-plan-items">
                                <!--********** Base Fee **********-->
                                <div class="gtmotive-plan-item">
                                    <div class="gtmotive-plan-item-title-and-price-container">
                                        <div class="gtmotive-plan-item-title">Grundgebühr</div>
                                        <div class="gtmotive-plan-item-price">
                                            {{ formatPrice(FLEX_PLAN_MONTHLY_BASE_FEE) }}
                                        </div>
                                    </div>
                                    <div class="label">inkl. 4 Kalkulationen</div>
                                </div>
                                <!--********** VIN Fees **********-->
                                <div class="gtmotive-plan-item">
                                    <div class="gtmotive-plan-item-title-and-price-container">
                                        <div class="gtmotive-plan-item-title">{{ reportsPerMonth }} VIN-Abfragen</div>
                                        <div class="gtmotive-plan-item-price">
                                            {{ formatPrice(vinCallsPrice()) }}
                                        </div>
                                    </div>
                                    <div class="label">à {{ formatPrice(VIN_CALL_PRICE) }}</div>
                                </div>
                                <!--********** Calculation Fees **********-->
                                <div class="gtmotive-plan-item">
                                    <div class="gtmotive-plan-item-title-and-price-container">
                                        <div class="gtmotive-plan-item-title">
                                            {{ flexPlanAdditionalCallsCalculation() }} Kalkulationen
                                        </div>
                                        <div class="gtmotive-plan-item-price">
                                            {{ formatPrice(flexPlanAdditionalCallsCalculationPrice()) }}
                                        </div>
                                    </div>
                                    <div class="label">à {{ formatPrice(CALCULATION_PRICE_FLEX_PLAN) }}</div>
                                </div>
                            </div>
                            <!--============================================-->
                            <!-- END Plan Items -->
                            <!--============================================-->
                            <!--********** Monthly Total **********-->
                            <div class="gtmotive-plan-price-container">
                                <div class="gtmotive-plan-price">
                                    {{ formatPrice(flexPlanMonthlyTotal()) }}
                                </div>
                                <div class="gtmotive-plan-billing-period">pro Monat</div>
                            </div>
                            <!--********** Contract Period **********-->
                            <div class="contract-period label">
                                Alle Preise netto.
                                <br />
                                Mindestvertragslaufzeit 6 Monate.
                                <br />
                                Danach monatlich kündbar.
                                <br />
                                &nbsp;
                            </div>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Flex Plan -->
                    <!--============================================-->
                    <!--============================================-->
                    <!-- Flatrate Plan -->
                    <!--============================================-->
                    @if (parseNumber(reportsPerMonth) >= 10) {
                        <div class="col-md">
                            <div class="gtmotive-plan-container">
                                <h2 class="gtmotive-plan-title">Pauschale</h2>
                                <div class="gtmotive-plan-subtitle label">vorher festgelegte Anzahl Abrufe</div>
                                <!--============================================-->
                                <!-- Plan Items -->
                                <!--============================================-->
                                <div class="gtmotive-plan-items">
                                    <!--********** Base Fee **********-->
                                    <div class="gtmotive-plan-item">
                                        <div class="gtmotive-plan-item-title-and-price-container">
                                            <div class="gtmotive-plan-item-title">Grundgebühr</div>
                                            <div class="gtmotive-plan-item-price">- €</div>
                                        </div>
                                        <div class="label">&nbsp;</div>
                                    </div>
                                    <!--********** VIN Fees **********-->
                                    <div class="gtmotive-plan-item">
                                        <div class="gtmotive-plan-item-title-and-price-container">
                                            <div class="gtmotive-plan-item-title">
                                                {{ reportsPerMonth }} VIN-Abfragen
                                            </div>
                                            <div class="gtmotive-plan-item-price">
                                                {{ formatPrice(vinCallsPrice()) }}
                                            </div>
                                        </div>
                                        <div class="label">à {{ formatPrice(VIN_CALL_PRICE) }}</div>
                                    </div>
                                    <!--********** Calculation Fees **********-->
                                    <div class="gtmotive-plan-item">
                                        <div class="gtmotive-plan-item-title-and-price-container">
                                            <div class="gtmotive-plan-item-title">
                                                {{ reportsPerMonth }} Kalkulationen
                                            </div>
                                            <div class="gtmotive-plan-item-price">
                                                {{ formatPrice(flatrateCalculationsPrice()) }}
                                            </div>
                                        </div>
                                        <div class="label">
                                            à {{ formatPrice(flatratePricePerCalculation()) }}
                                            <span
                                                class="help-indicator fancy-tooltip"
                                                data-tooltip-content="#calculation-graduation-of-prices-tooltip">
                                                i
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!--============================================-->
                                <!-- END Plan Items -->
                                <!--============================================-->
                                <!--********** Monthly Total **********-->
                                <div class="gtmotive-plan-price-container">
                                    @if (
                                        parseNumber(reportsPerMonth) * 12 <
                                        NUMBER_OF_CALCULATIONS_FOR_CUSTOM_RATE_IN_FLATRATE
                                    ) {
                                        <div class="gtmotive-plan-price">
                                            {{ formatPrice(flatrateMonthlyTotal()) }}
                                        </div>
                                    } @else {
                                        <div class="gtmotive-plan-price-by-arrangement">Nach Vereinbarung</div>
                                    }
                                    <div class="gtmotive-plan-billing-period">pro Monat</div>
                                </div>
                                <!--********** Contract Period **********-->
                                <div class="contract-period label">
                                    Alle Preise netto.
                                    <br />
                                    Vertragslaufzeit 12 Monate.
                                    <br />
                                    Danach Verlängerung um 12 Monate.
                                    <br />
                                    3 Monate vor Laufzeitende kündbar.
                                </div>
                            </div>
                        </div>
                    }
                    <!--============================================-->
                    <!-- END Flatrate Plan -->
                    <!--============================================-->
                }
            </div>
        </div>
    </section>

    <!--============================================-->
    <!-- Contact Form -->
    <!--============================================-->
    <section id="get-in-touch-with-gtmotive-section">
        <div class="container">
            @if (!contactFormSent) {
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h2 id="get-in-touch-with-gtmotive-heading">Kontakt zum Vertrieb von GT Motive</h2>
                        <p id="get-in-touch-with-gtmotive-intro">
                            Lass dich vom Vertrieb von GT Motive kontaktieren, der gemeinsam mit dir die passenden
                            Konditionen ermittelt.
                        </p>
                    </div>
                </div>
                <form class="row justify-content-center" (submit)="sendContactForm(); $event.preventDefault()">
                    <div class="col-md-5">
                        <div class="contact-form-input-container">
                            <input
                                type="text"
                                class="newsletter-input"
                                id="gtmotive-get-in-touch-organization-input"
                                placeholder="Firma"
                                required
                                [(ngModel)]="organization"
                                [ngModelOptions]="{ standalone: true }" />
                        </div>
                        <div class="contact-form-input-container multiple-inputs">
                            <input
                                type="text"
                                class="newsletter-input"
                                id="gtmotive-get-in-touch-first-name-input"
                                placeholder="Vorname"
                                required
                                [(ngModel)]="firstName"
                                [ngModelOptions]="{ standalone: true }" />
                            <input
                                type="text"
                                class="newsletter-input"
                                id="gtmotive-get-in-touch-last-name-input"
                                placeholder="Nachname"
                                required
                                [(ngModel)]="lastName"
                                [ngModelOptions]="{ standalone: true }" />
                        </div>
                        <div class="contact-form-input-container"></div>
                        <div class="contact-form-input-container multiple-inputs">
                            <input
                                type="text"
                                class="newsletter-input"
                                id="gtmotive-get-in-touch-zip-input"
                                placeholder="PLZ"
                                required
                                [(ngModel)]="zip"
                                (input)="getCityByZip()"
                                [ngModelOptions]="{ standalone: true }" />
                            <input
                                type="text"
                                class="newsletter-input"
                                id="gtmotive-get-in-touch-city-input"
                                placeholder="Ort"
                                required
                                [(ngModel)]="city"
                                [ngModelOptions]="{ standalone: true }" />
                        </div>
                        <div class="contact-form-input-container multiple-inputs">
                            <input
                                type="email"
                                class="newsletter-input"
                                id="gtmotive-get-in-touch-email-input"
                                placeholder="E-Mail"
                                required
                                [(ngModel)]="email"
                                [ngModelOptions]="{ standalone: true }" />
                            <input
                                type="tel"
                                class="newsletter-input"
                                id="gtmotive-get-in-touch-phone-input"
                                placeholder="Telefon"
                                required
                                [(ngModel)]="phone"
                                [ngModelOptions]="{ standalone: true }" />
                        </div>
                        <div id="transmit-usage-data-checkbox-container" class="text-center">
                            <input
                                type="checkbox"
                                id="transmit-usage-data"
                                name="transmit-usage-data"
                                [(ngModel)]="submitUsageData" />
                            <label for="transmit-usage-data">Daten des Preisrechners übermitteln</label>
                        </div>
                        <div class="contact-form-input-container">
                            <button
                                type="submit"
                                id="gtmotive-get-in-touch-submit-button"
                                class="newsletter-input primary-button"
                                [title]="gtmotiveContactFormComplete() ? '' : 'Bitte Kontaktdaten angeben'">
                                @if (contactFormSending) {
                                    <img
                                        class="pending-indicator"
                                        src="/assets/images/loading-indicator-white.svg"
                                        alt="" />
                                }
                                @if (!contactFormSending) {
                                    <span>Kontaktanfrage senden</span>
                                }
                            </button>
                        </div>
                    </div>
                </form>
                <div class="row justify-content-center text-center" id="gtmotive-contact-form-legal-info">
                    <div class="col-md-6 label">
                        <p>
                            Greifst du lieber zum Hörer und sprichst persönlich mit jemandem?
                            <br />
                            Hotline:
                            <a href="tel:+49 692 9957 1100">+49 692 9957 1100</a>
                            <br />
                        </p>
                        <p>
                            Deine Kontakdaten werden ausschließlich an uns (autoiXpert) und an GT Motive übermittelt.
                            <a href="/datenschutz" target="_blank" rel="noopener">Infos zum Datenschutz</a>
                            . Die Preise auf dieser Seite sind ein unverbindliches Hilfsmittel, es gelten stets die
                            offiziellen Preislisten von GT Motive.
                        </p>
                        <p>
                            Dieser GT Motive-Preisrechner wird von
                            <a href="/">autoiXpert</a>
                            zur Verfügung gestellt, einem zertifizierten Technologie-Partner von GT Motive.
                        </p>
                    </div>
                </div>
            }
            @if (contactFormSent) {
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h2>Anfrage gesendet</h2>
                        <p>Vielen Dank, deine Anfrage wurde an das Vertriebsteam von GT Motive weitergeleitet.</p>
                    </div>
                </div>
            }
        </div>
        <div id="newsletter-signup-response-container" class="container"></div>
    </section>
    <!--============================================-->
    <!-- END Contact Form -->
    <!--============================================-->

    <section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div id="features-link-container" class="exclamation-box-container">
                        <h3 class="exclamation-box-heading">Alles, was du als Gutachter brauchst</h3>
                        <div>Textbausteine, Kalkulationen, Buchhaltung & mehr.</div>
                        <a
                            class="exclamation-box-button button-solid light"
                            href="/kfz-sachverstaendigen-software?utm_source=gtmotive-preisrechner&utm_medium=exclamation-box-bottom">
                            autoiXpert entdecken
                        </a>
                        <a class="exclamation-box-button button-solid ghost-button-light" href="/registrierung">
                            Kostenfrei testen
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="tooltip_templates">
        <div id="calculation-graduation-of-prices-tooltip">
            Preisstaffelung (Kalkulationen pro Jahr):
            <ul>
                <li>
                    bis 200: {{ formatPrice(CALCULATION_PRICE_FLATRATE_BELOW_200, 2) }}
                    pro Stück
                </li>
                <li>
                    ab 200: {{ formatPrice(CALCULATION_PRICE_FLATRATE_ABOVE_200, 2) }}
                    pro Stück
                </li>
                <li>
                    ab 500: {{ formatPrice(CALCULATION_PRICE_FLATRATE_ABOVE_500, 2) }}
                    pro Stück
                </li>
                <li>ab {{ NUMBER_OF_CALCULATIONS_FOR_CUSTOM_RATE_IN_FLATRATE }}: Nach Vereinbarung</li>
            </ul>
        </div>
    </div>
</div>
