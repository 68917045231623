import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CUSTOMER_SUPPORT_AGENT_STRUCTURED_DATA } from './customer-support-agent.google-structured-data';
import { GoogleStructuredDataComponent } from '../../../shared/components/google-structured-data.component';

@Component({
    selector: 'ax-customer-support-agent',
    standalone: true,
    imports: [GoogleStructuredDataComponent, GoogleStructuredDataComponent],
    templateUrl: './customer-support-agent.component.html',
    styleUrl: '../job-details.scss',
})
export class CustomerSupportAgentComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    protected googleStructuredData = CUSTOMER_SUPPORT_AGENT_STRUCTURED_DATA;

    constructor() {
        this.titleService.setTitle('Customer Support Agent | Jobs');
        this.metaService.addTag({
            name: 'description',
            content:
                'Bist du smart und hast gerne Kundenkontakt? Starte deine Karriere bei autoiXpert und berate Kunden bei der führenden Software für Kfz-Gutachter.',
        });
    }
}
