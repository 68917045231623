import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ExclamationBoxComponent } from '../../shared/components/exclamation-box/exclamation-box.component';
import { DocumentBuildingBlocksBannerComponent } from './document-building-blocks-banner/document-building-blocks-banner.component';
import { AccordionComponent } from '../../shared/components/accordion/accordion.component';
import { Meta, Title } from '@angular/platform-browser';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'ax-fuer-wechsler',
    standalone: true,
    imports: [
        MatIconModule,
        ExclamationBoxComponent,
        DocumentBuildingBlocksBannerComponent,
        AccordionComponent,
        MatTooltipModule,
    ],
    templateUrl: './fuer-wechsler.component.html',
    styleUrl: './fuer-wechsler.component.scss',
})
export class FuerWechslerComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    constructor() {
        this.titleService.setTitle('Wechsel zu autoiXpert');
        this.metaService.addTag({
            name: 'description',
            content:
                'Wie funktioniert dein Wechsel zu autoiXpert? Ein kurzer Überblick über Import-Möglichkeiten, Einstellungen und Antworten auf häufig gestellte Fragen.',
        });
    }
}
