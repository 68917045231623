import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ConfigService } from '../../shared/services/config.service';

@Component({
    selector: 'ax-nutzungsbedingungen',
    standalone: true,
    imports: [],
    templateUrl: './nutzungsbedingungen.component.html',
    styleUrl: './nutzungsbedingungen.component.scss',
})
export class NutzungsbedingungenComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);
    protected readonly configService = inject(ConfigService);

    constructor() {
        this.titleService.setTitle('Nutzungsbedingungen');
        this.metaService.addTag({
            name: 'description',
            content:
                'Lesen Sie die Nutzungsbedingungen von autoiXpert. Jeder autoiXpert-Kunde hat diese Nutzungsbedingungen akzeptiert.',
        });
    }
}
