import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'ax-team',
    standalone: true,
    imports: [],
    templateUrl: './team.component.html',
    styleUrl: './team.component.scss',
})
export class TeamComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    constructor() {
        this.titleService.setTitle('Team');
        this.metaService.addTag({
            name: 'description',
            content:
                'Das sind die Köpfe hinter der KFZ-Gutachter-Software autoiXpert. Unser Team arbeitet jeden Tag daran, für Sachverständige mehr rauszuholen!',
        });
    }
}
