import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { slideInAndOutVertically } from '../../animations/slide-in-and-out-vertical.animation';

@Component({
    selector: 'ax-accordion',
    standalone: true,
    imports: [MatIconModule, NgIf],
    templateUrl: './accordion.component.html',
    styleUrl: './accordion.component.scss',
    animations: [slideInAndOutVertically()],
})
export class AccordionComponent {
    @Input() title = '';
    @Input() isExpanded: boolean = false;
}
