// Slide animation for the calculation provider logos
import { animate, style, transition, trigger } from '@angular/animations';

export function moveInFromTopLeft(duration = 500, delay = 500) {
    return trigger('moveInFromTopLeft', [
        transition(':enter', [
            style({
                transform: 'translate3d(-100%, -100%, 0)',
            }),
            animate(`${duration}ms ${delay}ms ease`, style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
        ]),
    ]);
}
