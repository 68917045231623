import { Component, inject, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { SeoService } from './shared/services/seo.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, HeaderComponent, FooterComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    private readonly seoService = inject(SeoService);

    title = 'autoixpert.de';

    constructor(
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: any,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.seoService.setCanonicalUrl();

        if (isPlatformBrowser(this.platformId)) {
            // Client side execution
            this.injectTrackingScripts();
        }
    }

    injectTrackingScripts() {
        const gtmScriptTag = this.renderer.createElement('script');
        gtmScriptTag.type = 'text/javascript';
        // TODO This is only the placeholder URL. Replace with our Google Analytics ID.
        gtmScriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=UA-XXXX';
        this.renderer.appendChild(this.document.body, gtmScriptTag);

        const gtagInitScript = this.renderer.createElement('script');
        gtagInitScript.type = 'text/javascript';
        gtagInitScript.text = `
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'UA-XXXX');
    `;
        this.renderer.appendChild(this.document.body, gtagInitScript);
    }
}
