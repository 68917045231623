<section id="intro-section">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-md-7">
                <h3 class="additional-headline">Wechsel zu autoiXpert</h3>
                <h1>Triff die beste Entscheidung deines Berufslebens.</h1>
                <p id="switching-to-ax-tagline">
                    Der Wechsel zu autoiXpert wird dein Büro verändern - hin zum papierlosen Büro, mit höherer
                    Produktivität und entspannterem Arbeiten.
                </p>
            </div>
        </div>
    </div>
</section>
<section id="key-features-section">
    <img
        id="blue-curve-globe"
        src="/assets/images/curve-blue-globe.svg"
        alt="Dekorativer, blauer Balken, über den Papierflieger fliegen"
        aria-hidden="true" />
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-4">
                <div class="frosted-glass-card">
                    <div class="frosted-glass-card-icon-container">
                        <mat-icon id="frosted-glass-card-import-icon">route</mat-icon>
                    </div>
                    <div class="frosted-glass-card-labels">
                        <h3 class="frosted-glass-card-title">Import-Schnittstellen</h3>
                        <!-- Don't let prettier format the following inline elements (to prevent whitespace between "Gutachten" and anchor) -->
                        <!-- prettier-ignore -->
                        <p class="frosted-glass-card-description">
                            für Kontakte, Kopfdaten von Gutachten<a
                            class="help-indicator fancy-tooltip"
                            target="_blank"
                            href="https://wissen.autoixpert.de/hc/de/articles/4404011313810-Gutachten-aus-Fremdsystem-importieren"
                            title="Zu den Kopfdaten gehören unter anderem Aktenzeichen, Typ und Fertigstellungsdatum des Gutachtens, sowie die wichtigsten Informationen zu Fahrzeug und Anspruchsteller. Für eine vollständige Liste aller Kopfdaten klicke auf das Fragezeichen.">
                            ?
                        </a>
                            & Rechnungen
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="frosted-glass-card">
                    <div class="frosted-glass-card-icon-container">
                        <mat-icon>description</mat-icon>
                    </div>
                    <div class="frosted-glass-card-labels">
                        <h3 class="frosted-glass-card-title">Briefpapier einrichten</h3>
                        <p class="frosted-glass-card-description">So einfach wie eine Word-Datei zu bearbeiten.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="frosted-glass-card">
                    <div class="frosted-glass-card-icon-container">
                        <mat-icon>headset_mic</mat-icon>
                    </div>
                    <div class="frosted-glass-card-labels">
                        <h3 class="frosted-glass-card-title">Kostenfreier Support</h3>
                        <p class="frosted-glass-card-description">Wir helfen beim Wechsel persönlich & kostenfrei.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="import-section">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-md-7">
                <h3 class="additional-headline">Import-Schnittstellen</h3>
                <h2>Was kann importiert werden?</h2>
            </div>
        </div>
    </div>

    <div id="import-cards" class="container">
        <div class="row justify-content-center">
            <div class="col-md-4">
                <div class="frosted-glass-card">
                    <div class="frosted-glass-card-icon-container">
                        <mat-icon>contacts</mat-icon>
                    </div>
                    <div class="frosted-glass-card-labels">
                        <h3 class="frosted-glass-card-title">Kontakte</h3>
                        <p class="frosted-glass-card-subtitle">
                            Sei schneller startklar, indem du Kontakte importierst.
                        </p>
                        <p class="frosted-glass-card-description">
                            Anspruchsteller
                            <br />
                            Rechtsanwälte
                            <br />
                            Werkstätten
                            <br />
                            ...und viele mehr.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="frosted-glass-card">
                    <div class="frosted-glass-card-icon-container">
                        <mat-icon>description</mat-icon>
                    </div>
                    <div class="frosted-glass-card-labels">
                        <h3 class="frosted-glass-card-title">Kopfdaten von Gutachten</h3>
                        <p class="frosted-glass-card-subtitle">
                            Ermöglicht einen Warnhinweis, falls ein Fahrzeug bereits mit deiner bisherigen Software
                            begutachtet wurde.
                        </p>
                        <p class="frosted-glass-card-description">
                            VIN
                            <br />
                            Kennzeichen
                            <br />
                            Anspruchsteller
                            <br />
                            Aktenzeichen
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="frosted-glass-card">
                    <div class="frosted-glass-card-icon-container">
                        <mat-icon>euro_symbol</mat-icon>
                    </div>
                    <div class="frosted-glass-card-labels">
                        <h3 class="frosted-glass-card-title">Rechnungen</h3>
                        <p class="frosted-glass-card-subtitle">
                            Nützlich, um offene Rechnungen mit autoiXpert weiter zu verfolgen.
                        </p>
                        <p class="frosted-glass-card-description">
                            Je nach Software:
                            <br />
                            Rechnungsdaten
                            <br />
                            Rechnungs-PDFs
                            <br />
                            Sprich uns an!
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <img
                id="connection-lines"
                src="/assets/images/autoixpert-import-connection-lines.svg"
                alt="Verbindungslinien, die den Import von Daten zu autoiXpert visualisieren."
                aria-hidden="true" />
            <div class="autoixpert-logo-glass-card">
                <img
                    src="/assets/images/logo-autoixpert.svg"
                    alt="AutoiXpert-Logo auf umrandeter Kachel"
                    aria-hidden="true" />
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row justify-content-center disclaimer">
            <div class="col-md-6">
                <p class="label-ax-app text-center">
                    Import-Möglichkeiten für Kontakte & Kopfdaten von Gutachten bestehen für folgende Drittsysteme.
                    Daten anderer Systeme können über den freien Import importiert werden. Für Rechnungsimporte sprich
                    uns an!
                </p>
            </div>
        </div>

        <div id="third-party-provider-container" class="row justify-content-center text-center">
            <div class="col-6 col-md-2">
                <img
                    class="third-party-provider-logo fancy-tooltip"
                    title="Audatex AudaFusion"
                    src="/assets/images/logos/logo-audatex-orange-rim.png"
                    alt="Audatex-Logo" />
            </div>
            <div class="col-6 col-md-2">
                <img class="third-party-provider-logo" src="/assets/images/logos/logo-dynarex.png" alt="Dynarex-Logo" />
            </div>
            <div class="col-6 col-md-2">
                <img
                    class="third-party-provider-logo"
                    src="/assets/images/logos/logo-easyexpert-pro.png"
                    alt="easyExpert-Logo" />
            </div>
            <div class="col-6 col-md-2">
                <img
                    class="third-party-provider-logo"
                    src="/assets/images/logos/logo-nextsoft.png"
                    alt="neXtsoft-Logo" />
            </div>
        </div>

        <div class="row justify-content-center disclaimer">
            <div class="col-md-6">
                <p class="label-ax-app text-center">
                    Die Marken Audatex, AudaFusion, EasyExpert, neXtsoft und Dynarex sind Eigentum ihrer jeweiligen
                    Inhaber und sind nicht mit autoiXpert assoziiert.
                </p>
            </div>
        </div>
    </div>
</section>

<ax-document-building-blocks-banner></ax-document-building-blocks-banner>

<section id="courses-and-videos-section">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-md-7">
                <h3 class="additional-headline">Schulungen & Videos</h3>
                <h2>
                    Schaffe Sicherheit & Akzeptanz
                    <br />
                    bei deinem Team
                </h2>
            </div>
        </div>
    </div>

    <div id="courses-and-videos-cards" class="container">
        <div class="row justify-content-center">
            <div class="col-md-4">
                <div class="highlight-card">
                    <a class="highlight-card-icon-container" href="/Webinar" target="_blank">
                        <mat-icon>connected_tv</mat-icon>
                    </a>
                    <div class="highlight-card-labels">
                        <h3 class="highlight-card-title">Kostenfreie Webinare</h3>
                        <p class="highlight-card-description">
                            Alle 4 Wochen
                            <a href="/Webinar" target="_blank">demonstrieren wir online</a>
                            ein vollständiges Haftpflichtgutachten.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="highlight-card">
                    <a
                        class="highlight-card-icon-container"
                        href="https://www.youtube.com/@autoiXpert"
                        target="_blank"
                        rel="noopener">
                        <mat-icon>smart_display</mat-icon>
                    </a>
                    <div class="highlight-card-labels">
                        <h3 class="highlight-card-title">YouTube-Channel</h3>
                        <p class="highlight-card-description">
                            <a href="https://www.youtube.com/@autoiXpert" target="_blank" rel="noopener">Videos</a>
                            kostenfrei und on-demand schauen, um sich und andere einzuarbeiten.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="highlight-card">
                    <div class="highlight-card-icon-container">
                        <mat-icon>lightbulb</mat-icon>
                    </div>
                    <div class="highlight-card-labels">
                        <h3 class="highlight-card-title">Intuitive Oberfläche</h3>
                        <p class="highlight-card-description">
                            Viele Funktionen sind selbsterklärend und minimieren den Schulungsaufwand.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div id="courses-and-videos-second-row" class="row justify-content-center">
            <div class="col-md-4">
                <div class="highlight-card">
                    <div class="highlight-card-icon-container">
                        <mat-icon>person_add</mat-icon>
                    </div>
                    <div class="highlight-card-labels">
                        <h3 class="highlight-card-title">Eigener Zugang</h3>
                        <p class="highlight-card-description">
                            Zugänge für Mitarbeiter sind unkompliziert online einrichtbar.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="highlight-card">
                    <div class="highlight-card-icon-container">
                        <mat-icon>groups</mat-icon>
                    </div>
                    <div class="highlight-card-labels">
                        <h3 class="highlight-card-title">Kollaboration</h3>
                        <p class="highlight-card-description">
                            Gutachten können allein oder im Team bearbeitet werden.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="highlight-card">
                    <div class="highlight-card-icon-container">
                        <mat-icon>key</mat-icon>
                    </div>
                    <div class="highlight-card-labels">
                        <h3 class="highlight-card-title">Zugriffsrechte</h3>
                        <p class="highlight-card-description">
                            Der Zugriff auf Daten & Funktionen lässt sich granular steuern.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="faq-section">
    <div class="container justify-content-center">
        <div class="row justify-content-center">
            <!--********** Section Heading **********-->
            <!-- If you change the md to another breakpoint, update the SCSS of the FAQ section heading container too. -->
            <div class="col-md-4">
                <div id="faq-section-heading-container">
                    <h3 class="additional-headline">FAQS</h3>
                    <h2>Häufige Fragen</h2>
                </div>
            </div>

            <!--********** Questions **********-->
            <div class="col-md-8">
                <ax-accordion title="Schaffe ich den Wechsel neben dem Tagesgeschäft?">
                    Generell ist das machbar. Wir empfehlen aber, dir je nach Bürogröße und Historie ein paar Stunden
                    bis einen Tag für die Einstellung der Textbausteine und die Datenimporte zu blocken. Das braucht ein
                    paar Stunden konzentrierter Arbeit am Stück. Wir geben dir telefonisch gerne Schützenhilfe bei
                    Fragen.
                </ax-accordion>
                <ax-accordion title="Muss ich neue Hardware anschaffen?">
                    autoiXpert läuft auf gängigen PCs und Macs. Weil du nun aber Unterschriften mit dem Tablet einholen
                    kannst, wäre ein Tablet mit einem Stift eine coole Erweiterung deines Repertoires 😎 Wir empfehlen
                    Apple-Geräte, du kannst aber auch Android-Tablets nutzen, weil autoiXpert überall im Browser läuft -
                    <a href="https://www.youtube.com/watch?v=wp81bNUqc_s" target="_blank" rel="noopener">
                        sogar offline ohne Netz.
                    </a>
                </ax-accordion>

                <ax-accordion
                    title="Was mache ich mit meinen Daten im bisherigen System, wenn ich mal zu Gericht muss?">
                    Manche Anbieter erlauben einen dauerhaften Lesezugriff auf alte Vorgänge. Sprich dazu am besten mit
                    deinem Anbieter.
                    <br />
                    <br />
                    Was immer funktioniert: Lege dir ein PDF-Archiv an. Falls du deine Gutachten bisher noch nicht als
                    PDF abgelegt hast: Sprich am besten deinen Anbieter darauf an, deine Gutachten als PDF zu
                    exportieren.
                </ax-accordion>
                <!-- Don't let prettier format the following inline elements (to prevent whitespace between anchor and comma) -->
                <!-- prettier-ignore -->
                <ax-accordion title="Ich möchte, dass mein Gutachten am Markt einzigartig aussieht. Geht das?">
                    Ja! Individuelles Layout ist eine Stärke von autoiXpert. Deinem Briefpapier sind hinsichtlich
                    Kreativität
                    keine Grenzen gesetzt und selbst unsere komplexen Textbausteine mit Tabellen und Abbildungen lassen
                    sich
                    (gegen Gebühr) von unseren Experten anpassen.
                    <br/>
                    <br/>
                    <a href="/Kfz-Sachverstaendigen-Software#professional-layout-section">Hol dir etwas Inspiration</a>,
                    was
                    mit
                    autoiXpert alles möglich ist.
                </ax-accordion>
                <ax-accordion title="Muss ich meine Daten weiterhin sichern?">
                    Das übernehmen wir für dich: tagsüber alle zwei Stunden und nachts alle vier.
                </ax-accordion>
                <ax-accordion title="Was ist, wenn ich mal kein Internet habe?">
                    autoiXpert ist eine Browser-Applikation, nutzt aber moderne Technologien, die es dir erlauben, deine
                    Daten auch offline einzusehen oder zu bearbeiten.
                    <br />
                    Ausgenommen von diesem Versprechen ist die Kommunikation mit Partner-Servern, z. B. DAT und Audatex,
                    für die du immer online sein musst.
                </ax-accordion>
            </div>
        </div>
    </div>
</section>

<ax-exclamation-box
    headline="Deine nächsten Schritte"
    secondButtonTitle="Kontakt"
    secondButtonLink="/Kontakt"
    [limitTextWidthTo50Percent]="true">
    Telefoniere unverbindlich mit einem Experten,
    <br />
    schau auf
    <a class="white-link" href="https://www.youtube.com/@autoiXpert" target="_blank" rel="noopener">YouTube</a>
    oder in unserem
    <a class="white-link" href="/Webinar" target="_blank">Webinar</a>
    vorbei
    <br />
    oder starte mit deinem eigenen Test-Account.
</ax-exclamation-box>
