import { Component, Input } from '@angular/core';

export interface WebinarInstructor {
    // Name used internally (e.g. stored in database)
    name: string;
    // Optional name (instead of above name) displayed on the webinar page to the user (might include a title like B. Sc.)
    displayName?: string;
    altDescription: string;
    imagePath: string;
    description: Array<string>;
}

@Component({
    selector: 'ax-webinar-instructor-section',
    standalone: true,
    imports: [],
    templateUrl: './webinar-instructor-section.component.html',
    styleUrl: './webinar-instructor-section.component.scss',
})
export class WebinarInstructorSectionComponent {
    @Input() instructors: Array<WebinarInstructor> = [];
    @Input() isOnlineExchange: boolean = false;
}
