import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'ax-gutachter-werden',
    standalone: true,
    imports: [],
    templateUrl: './gutachter-werden.component.html',
    styleUrl: './gutachter-werden.component.scss',
})
export class GutachterWerdenComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    constructor() {
        this.titleService.setTitle('Kfz-Gutachter werden');
        this.metaService.addTag({
            name: 'description',
            content:
                'Wie werde ich Kfz-Gutachter? Von Voraussetzungen bis hin zu geeigneten Ausbildungspartnern geben wir Ihnen einen guten Überblick.',
        });
    }
}
