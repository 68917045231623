import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'ax-kontakt',
    standalone: true,
    imports: [FormsModule],
    templateUrl: './kontakt.component.html',
    styleUrl: './kontakt.component.scss',
})
export class KontaktComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    protected inputDelayTimer?: ReturnType<typeof setTimeout> | null;
    protected articleSearchResults: Array<{
        html_url: string;
        title: string;
    }> | null = null;
    protected articleSearchPending = false;
    protected searchTerm: string = '';
    protected searchSuggestions = [
        {
            label: 'Briefpapier hinterlegen',
            url: 'https://wissen.autoixpert.de/hc/de/articles/360024274991-Wie-kann-ich-das-Briefpapier-meiner-Dokumente-anpassen-',
        },
        {
            label: 'Aktenzeichen einstellen',
            url: 'https://wissen.autoixpert.de/hc/de/articles/360003993192-Wie-stelle-ich-meine-Gutachtennummern-Aktenzeichen-ein-',
        },
        {
            label: 'Rechnungsnummer einstellen',
            url: 'https://wissen.autoixpert.de/hc/de/articles/360004032791-Wie-stelle-ich-Rechnungsnummern-ein-',
        },
        {
            label: 'DAT-Zugangsdaten',
            url: 'https://wissen.autoixpert.de/hc/de/articles/360003976032-Wo-hinterlege-ich-die-Zugangsdaten-der-DAT-',
        },
        {
            label: 'Lokaler Restwertverteiler',
            url: 'https://wissen.autoixpert.de/hc/de/articles/360008386720-Wie-kann-ich-eine-regionale-Restwertanfrage-in-autoiXpert-erstellen-',
        },
        {
            label: 'Restwertgebot manuell anlegen',
            url: 'https://wissen.autoixpert.de/hc/de/articles/360007302951-Wie-ermittle-ich-den-Restwert-in-autoiXpert-',
        },
        {
            label: 'MwSt.-Ausgleich einfordern',
            url: 'https://wissen.autoixpert.de/hc/de/articles/360025981272-Wie-kann-ich-Zusatzdokumente-wie-Ausgleich-Mehrwertsteuer-erstellen-',
        },
    ];

    constructor() {
        this.titleService.setTitle('Kontakt');
        this.metaService.addTag({
            name: 'description',
            content:
                'Kontaktieren Sie uns bei Fragen rund um die Software für KFZ-Sachverständige autoiXpert. Lernen wir uns kennen, um Ihre Arbeit als Sachverständiger zu optimieren.',
        });
    }

    protected fetchArticles(searchTerm: string) {
        if (!searchTerm) {
            return;
        }

        fetch(`https://wissen.autoixpert.de/api/v2/help_center/articles/search.json?query=${searchTerm}`)
            .then((response) => response.json())
            .then((helpcenterResponse) => {
                this.articleSearchResults = helpcenterResponse.results.slice(0, 7);
                this.articleSearchPending = false;
            })
            .catch(() => {
                alert('Suche gescheitert. Bitte kontaktiere die autoiXpert-Hotline.');
                this.articleSearchPending = false;
            });
    }

    protected fetchArticlesWithDelay(delayInMilliseconds = 300) {
        this.articleSearchResults = null;
        this.articleSearchPending = true;

        if (this.inputDelayTimer) {
            clearTimeout(this.inputDelayTimer);
            this.inputDelayTimer = null;
        }
        if (!this.searchTerm) {
            this.articleSearchPending = false;
            return;
        }
        this.inputDelayTimer = setTimeout(() => this.fetchArticles(this.searchTerm), delayInMilliseconds);
    }
}
