<ax-google-structured-data [json]="googleStructuredData" />
<!--============================================-->
<!-- Hero -->
<!--============================================-->
<section id="header-and-hero-section">
    <section id="hero-section">
        <div class="container">
            <div class="row">
                <div id="hero-text-container" class="col-md-12">
                    <h3 class="additional-headline">Software für Kfz-Gutachter</h3>
                    <h1 id="hero-headline">Die besten Gutachten starten mit autoiXpert</h1>
                    <p id="hero-tagline">
                        Erstelle jedes Gutachten so einfach wie noch nie.
                        <br />
                        Mit der Software, der in Deutschland über 2.000 Kfz-Sachverständige vertrauen.
                    </p>
                    <div id="hero-play-button-wrapper">
                        <div id="hero-play-button-container" (click)="videoPlayer.openYoutubePlayer()">
                            <div id="hero-play-button-icon-container">
                                <svg id="hero-play-icon" viewBox="0 0 24 24">
                                    <path d="M8 5v14 l 11 -7 z"></path>
                                </svg>
                            </div>
                            <div id="hero-play-button-label">Video abspielen</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-12 d-flex justify-content-center">
                    <div id="hero-screenshot-container">
                        <picture>
                            <source
                                type="image/webp"
                                srcset="/assets/images/screenshots/hero-autoixpert-photo-editor.webp" />
                            <img
                                id="hero-screenshot"
                                src="/assets/images/screenshots/hero-autoixpert-photo-editor.jpg"
                                alt="Fotobearbeitung mit der Gutachten-Software autoiXpert" />
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
<!--============================================-->
<!-- END Hero -->
<!--============================================-->
<ax-hero-video-player></ax-hero-video-player>

<section id="partners-section">
    <div class="container">
        <div id="partner-logos-container" class="row justify-content-center">
            <!--********** GTÜ **********-->
            <div class="partner-logo-container col-sm">
                <img
                    src="/assets/images/logos/gtue-logo.svg"
                    id="partnerlogo-gtue"
                    alt="GTÜ Logo"
                    class="partner-logo fancy-tooltip"
                    title="Nutze als GTÜ-Büro optional die zentrale GTÜ-Vorlage." />
            </div>

            <!--********** DAT **********-->
            <div class="partner-logo-container col-sm">
                <img
                    src="/assets/images/logos/dat-logo-no-text.svg"
                    id="partnerlogo-dat"
                    alt="DAT Deutschland Logo"
                    class="partner-logo fancy-tooltip"
                    title="Exportiere & re-importiere mit je einem Klick zu SilverDAT 3 der DAT. autoiXpert ist zertifizierter Schnittstellenpartner der DAT." />
            </div>

            <!--********** Audatex **********-->
            <div class="partner-logo-container col-sm">
                <img
                    src="/assets/images/logos/logo-solera-audatex.svg"
                    id="partnerlogo-audatex"
                    alt="Audatex Logo"
                    class="partner-logo fancy-tooltip"
                    title="Kalkuliere nahtlos mit Qapter von Audatex oder nutze AUTOonline und VALUEpilot. autoiXpert ist zertifizierter Schnittstellenpartner & strategischer Partner von Audatex." />
            </div>

            <!--********** GT Motive **********-->
            <!--            <div class="partner-logo-container col-sm">-->
            <!--                <img src="/assets/images/logos/gtmotive-logo.png" id="partnerlogo-gtmotive" alt="GT Motive Logo"-->
            <!--                     class="partner-logo fancy-tooltip"-->
            <!--                     title="Nutze die Kalkulation von GT Motive. autoiXpert ist zertifizierter Schnittstellenpartner von GT Motive.">-->
            <!--            </div>-->
            <!--********** ZA Online / GDV **********-->
            <div class="partner-logo-container col-sm">
                <img
                    src="/assets/images/logos/za-online-gdv.svg"
                    id="partnerlogo-gdv"
                    alt="Z@Online GDV Logo"
                    class="partner-logo fancy-tooltip"
                    title="Nutze die Identifikation der gegnerischen Versicherung kostenlos und komfortabel mit einem Klick über den Zentralruf des GDV (auch bekannt als Z@Online), direkt aus autoiXpert." />
            </div>

            <!--********** CarTV **********-->
            <div class="partner-logo-container col-sm">
                <img
                    src="/assets/images/logos/cartv-logo.png"
                    id="partnerlogo-cartv"
                    alt="CarTV Logo"
                    class="partner-logo fancy-tooltip"
                    title="Exportiere Fahrzeuge reibungslos zur Restwertbörse und Marktwertanalyse von CARTV." />
            </div>

            <!--********** Winvalue **********-->
            <div class="partner-logo-container col-sm">
                <img
                    src="/assets/images/logos/winvalue-logo.png"
                    id="partnerlogo-winvalue"
                    alt="WinValue Logo"
                    class="partner-logo fancy-tooltip"
                    title="Exportiere Fahrzeuge zur Restwertbörse und Marktwertanalyse von WinValue." />
            </div>
        </div>
    </div>
</section>

<section id="events-section">
    <div class="container">
        <div class="row justify-content-center align-items-center text-center text-lg-left">
            <!--********** Heading **********-->
            <div class="col-lg-3">
                <h2 id="events-section-heading">Live erleben</h2>
            </div>

            <!--********** Events **********-->
            @for (event of shownEvents; track event.date) {
                <div class="col-lg event-container">
                    <h2 class="event-heading">{{ event.date | eventDate }}</h2>
                    <div>
                        <!--********** Base64-encoded date **********-->
                        @if (event.link) {
                            <a href="{{ event.link }}" [innerHTML]="event.title"></a>
                        } @else {
                            <span [innerHTML]="event.title"></span>
                        }
                    </div>
                </div>
            }
        </div>

        <!--********** Kfz-SV-Forum **********-->
        <!--    <div-->
        <!--      class="row justify-content-center align-items-center text-center text-md-left"-->
        <!--      style="-->
        <!--        margin-top: 20px;-->
        <!--        border-top: 1px solid #f0f1f6;-->
        <!--        padding-top: 15px;-->
        <!--      ">-->
        <!--      <div class="col-md-3 text-center">-->
        <!--        <a-->
        <!--          href="https://www.kfz-sachverstaendigen-forum.de/de"-->
        <!--          target="_blank"-->
        <!--          rel="noopener">-->
        <!--          <img-->
        <!--            src="/assets/images/logos/kfz-sv-forum.png"-->
        <!--            alt="Logo des KFZ-Sachverständigen-Forums"-->
        <!--            style="width: 70px" />-->
        <!--        </a>-->
        <!--      </div>-->
        <!--      <div class="col-md-9 event-container" style="">-->
        <!--        <div>-->
        <!--          <strong>Kfz-Sachverständigenforum am 06. Februar 2024</strong>-->
        <!--          in Würzburg.-->
        <!--          <br />-->
        <!--          <a-->
        <!--            href="https://www.kfz-sachverstaendigen-forum.de/#"-->
        <!--            target="_blank"-->
        <!--            rel="noopener">-->
        <!--            Anmeldung-->
        <!--          </a>-->
        <!--          - Rabatt-Code für Tickets:-->
        <!--          <i>GASTKARTE_20_KFZSF24</i>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->

        <!--********** VKS **********-->
        <!--        <div class="row justify-content-center align-items-center text-center text-md-left"-->
        <!--             style="margin-top: 20px; border-top: 1px solid #f0f1f6; padding-top: 15px;">-->
        <!--            <div class="col-md-3 text-center">-->
        <!--                <a href="https://vks-24.de/sachverstaendigentag-2023-40-jahre-vks/" target="_blank" rel="noopener">-->
        <!--                    <img src="/assets/images/logos/vks-logo.png" style="width: 50px;" alt="VKS Logo">-->
        <!--                </a>-->
        <!--            </div>-->
        <!--            <div class="col-md-9 event-container" style="">-->
        <!--                <div>-->
        <!--                    <strong>VKS-Tag am 29. April 2023</strong> in München.-->
        <!--                    <a href="https://vks-24.de/sachverstaendigentag-2023-40-jahre-vks/" target="_blank" rel="noopener">Mehr »</a>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

        <!--********** CARTV **********-->
        <!--        <div-->
        <!--            class="row justify-content-center align-items-center text-center text-md-left"-->
        <!--            style="margin-top: 20px; border-top: 1px solid #f0f1f6; padding-top: 15px">-->
        <!--            <div class="col-md-3 text-center">-->
        <!--                <img src="/assets/images/logos/cartv-logo.png" style="width: 50px" alt="CARTV Logo" />-->
        <!--            </div>-->
        <!--            <div class="col-md-9 event-container" style="">-->
        <!--                <div>-->
        <!--                    <strong>CARTV-Forum am 07. Mai 2024</strong>-->
        <!--                    in München.-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

        <!--********** BVSK **********-->
        <!--        <div-->
        <!--            class="row justify-content-center align-items-center text-center text-md-left"-->
        <!--            style="margin-top: 20px; border-top: 1px solid #f0f1f6; padding-top: 15px">-->
        <!--            <div class="col-md-3 text-center">-->
        <!--                <a href="https://www.bvsk.de/service/sachverstaendigentag/" target="_blank" rel="noopener">-->
        <!--                    <img src="/assets/images/logos/bvsk.svg" style="width: 70px" alt="BVSK Logo" />-->
        <!--                </a>-->
        <!--            </div>-->
        <!--            <div class="col-md-9 event-container" style="">-->
        <!--                <div>-->
        <!--                    <strong>BVSK-Tag am 7. Juni 2024</strong>-->
        <!--                    in Regensburg.-->
        <!--                    <a href="https://www.bvsk.de/service/sachverstaendigentag/" target="_blank" rel="noopener">-->
        <!--                        Mehr »-->
        <!--                    </a>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

        <!--********** GTÜ Bundeskongress **********-->
        <!--        <div class="row justify-content-center align-items-center text-center text-md-left"-->
        <!--             style="margin-top: 20px; border-top: 1px solid #f0f1f6; padding-top: 15px;">-->
        <!--            <div class="col-md-3 text-center">-->
        <!--                <a href="https://www.gtue.de/de/die-gtu/veranstaltungen" target="_blank"-->
        <!--                   rel="noopener"><img src="/assets/images/logos/gtue-logo.svg" style="width: 70px;" alt="GTÜ Logo"></a>-->
        <!--            </div>-->
        <!--            <div class="col-md-9 event-container" style="">-->
        <!--                <div>-->
        <!--                    <strong>GTÜ-Bundeskongress vom 06.-07. Oktober 2023</strong> in Düsseldorf.-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

        <!--********** Deutscher Schadenrechtstag **********-->
        <div
            class="row justify-content-center align-items-center text-center text-md-left"
            style="margin-top: 20px; border-top: 1px solid #f0f1f6; padding-top: 15px">
            <div class="col-lg-3 text-center">
                <a
                    href="https://shop.reguvis.de/kongresstagung/deutscher-schadenrechtstag/"
                    target="_blank"
                    rel="noopener">
                    <img src="/assets/images/logos/reguvis-logo.svg" style="width: 70px" alt="Reguvis Logo" />
                </a>
            </div>
            <div class="col-lg event-container" style="">
                <div>
                    <strong>Deutscher Schadenrechtstag am 14. November 2024</strong>
                    in Köln.
                    <a
                        href="https://shop.reguvis.de/kongresstagung/deutscher-schadenrechtstag/"
                        target="_blank"
                        rel="noopener">
                        Infos »
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="Tour">
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <div id="intro-container">
                    <h3 class="additional-headline">Speed. Performance. Design.</h3>
                    <h2 id="intro-headline">
                        KFZ-Gutachtensoftware für
                        <br class="d-none d-md-block" />
                        das 21. Jahrhundert
                    </h2>
                    <p id="intro-text">
                        Nimm Schäden in wenigen Schritten auf, ermittele den Fahrzeugwert
                        <br class="d-none d-md-inline" />
                        in Sekunden und lass die Software auf Basis deiner Eingaben
                        <br class="d-none d-md-inline" />
                        ein hochwertiges Gutachten zusammenstellen.
                    </p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div id="intro-screenshots-container">
                    <!--********** Report List **********-->
                    <div id="intro-screenshot-container-report-list" class="intro-screenshot-container">
                        <img
                            class="intro-screenshot lazyload"
                            data-src="/assets/images/screenshots/gutachtenuebersicht.jpg"
                            alt="Screenshot Gutachtenübersicht" />
                        <div
                            class="screenshot-info-indicator indicator-1 fancy-tooltip"
                            title="Durchsuche Name, Nummernschild, Datum, Modell und vieles mehr mit nur einem Suchfeld."></div>
                        <div
                            class="screenshot-info-indicator indicator-2 fancy-tooltip"
                            title="Blende Details zum Gutachten ein, z. B. wer welche Dokumente erhalten hat."></div>
                    </div>

                    <!--********** Car Condition **********-->
                    <div id="intro-screenshot-container-car-condition" class="intro-screenshot-container hidden">
                        <img
                            class="intro-screenshot lazyload"
                            data-src="/assets/images/screenshots/fahrzeugzustand.jpg"
                            alt="Screenshot Fahrzeugzustand" />
                        <div
                            class="screenshot-info-indicator indicator-1 fancy-tooltip"
                            title="Dokumentiere Schadenstexte direkt in der Anstoßzeichnung oder in einem unbegrenzten Freitextfeld."></div>
                        <div
                            class="screenshot-info-indicator indicator-2 fancy-tooltip"
                            title="Nützliche Shortcuts erleichtern dir die Arbeit über den gesamten Gutachtenprozess hinweg."></div>
                    </div>

                    <!--********** Photo Editor **********-->
                    <div id="intro-screenshot-container-photo-editor" class="intro-screenshot-container upcoming right">
                        <img
                            class="intro-screenshot lazyload"
                            data-src="/assets/images/screenshots/fotoeditor.jpg"
                            alt="Screenshot Foto-Editor" />
                        <div
                            class="screenshot-info-indicator indicator-1 fancy-tooltip"
                            title="Bearbeite eine zweite, unabhängige Foto-Version für die Restwertbörsen, z. B. um Nummernschilder zu schwärzen. Du kannst Fotos auch komplett ausblenden."></div>
                        <div
                            class="screenshot-info-indicator indicator-2 fancy-tooltip"
                            title="Füge besonders häufig genutzte Beschreibungen einer Liste hinzu, aus der du beim nächsten Gutachten schneller wählen kannst."></div>
                        <div
                            class="screenshot-info-indicator indicator-3 fancy-tooltip"
                            title="Alle Formen & Texte sind nachträglich bearbeitbar, unabhängig von der Reihenfolge, in der sie gesetzt wurden."></div>
                    </div>

                    <!--********** Calculation **********-->
                    <div id="intro-screenshot-container-calculation" class="intro-screenshot-container upcoming left">
                        <img
                            class="intro-screenshot lazyload"
                            data-src="/assets/images/screenshots/kalkulation.jpg"
                            alt="Screenshot Kalkulation" />
                        <div
                            class="screenshot-info-indicator indicator-1 fancy-tooltip"
                            title="Keine passenden Kostensätze zur Hand? Übertrage mit einem Klick die DEKRA-Kostensätze für die PLZ des Anspruchstellers."></div>
                        <div
                            class="screenshot-info-indicator indicator-2 fancy-tooltip"
                            title="Exportiere mit einem Klick die Fahrzeugdaten zur Schadenskalkulation von Audatex, DAT oder GT Motive & hole die Ergebnisse ebenso schnell wieder zurück."></div>
                        <div
                            class="screenshot-info-indicator indicator-3 fancy-tooltip"
                            title="Stelle Fotos, Fahrzeugdaten & alles Weitere mit einem Klick in die Restwertbörse deiner Wahl ein. Die Kalkulation wird automatisch anonymisiert."></div>
                    </div>

                    <!--********** Diminished Value **********-->
                    <div
                        id="intro-screenshot-container-diminished-value"
                        class="intro-screenshot-container upcoming left">
                        <img
                            class="intro-screenshot lazyload"
                            data-src="/assets/images/screenshots/minderwertrechner.jpg"
                            alt="Screenshot Minderwertrechner" />
                        <div
                            class="screenshot-info-indicator indicator-1 fancy-tooltip"
                            title="Ermittele den Minderwert mit bis zu 6 Methoden, ohne die Software zu verlassen."></div>
                        <div
                            class="screenshot-info-indicator indicator-2 fancy-tooltip"
                            title="Damit du möglichst einfach die richtige Option auswählen kannst, ist jedes Feld über Tooltips beschrieben. So wie an vielen Stellen in autoiXpert."></div>
                    </div>

                    <!--********** Local Residual Value Request **********-->
                    <div
                        id="intro-screenshot-container-residual-value-request"
                        class="intro-screenshot-container upcoming left">
                        <img
                            class="intro-screenshot lazyload"
                            data-src="/assets/images/screenshots/lokaler-restwertverteiler.jpg"
                            alt="Screenshot Lokaler Restwertverteiler" />
                        <div
                            class="screenshot-info-indicator indicator-1 fancy-tooltip"
                            title="Holst du Restwertgebote häufig lokal per Telefon ein? Lass deinen Bietern über den kostenfreien integrierten Restwertverteiler alle wichtigen Infos zukommen und lass sie direkt online bieten. Gebote werden dir automatisch in autoiXpert angezeigt."></div>
                        <div
                            class="screenshot-info-indicator indicator-2 fancy-tooltip"
                            title="Deine Bieter zoomen Fotos mit einem einfachen Klick."></div>
                        <div
                            class="screenshot-info-indicator indicator-3 fancy-tooltip"
                            title="Der Restwertverteiler ist mobilfähig. Das heißt, deine Bieter können auch direkt am Smartphone bieten."></div>
                    </div>

                    <!--********** Invoice List **********-->
                    <div id="intro-screenshot-container-invoice-list" class="intro-screenshot-container upcoming left">
                        <img
                            class="intro-screenshot lazyload"
                            data-src="/assets/images/screenshots/rechnungsuebersicht.jpg"
                            alt="Screenshot Rechnungsübersicht" />
                        <div
                            class="screenshot-info-indicator indicator-1 fancy-tooltip"
                            title="Grafische Auswertungen zu Verdienst und Forderungen zeigen dir immer, wo du mit deinem Unternehmen stehst."></div>
                        <div
                            class="screenshot-info-indicator indicator-2 fancy-tooltip"
                            title="Erfasse den Zahlungsstatus einer Rechnung mit nur einem Klick oder gib Teilzahlungen, Kürzungen & mehr an. Mahnungen kannst du auch schreiben."></div>
                        <div
                            class="screenshot-info-indicator indicator-3 fancy-tooltip"
                            title="Bei Abschluss eines Gutachtens generiert autoiXpert automatisch eine Rechnung. Du kannst aber auch individuelle Rechnungen schreiben."></div>
                    </div>

                    <!--********** Analytics **********-->
                    <div id="intro-screenshot-container-analytics" class="intro-screenshot-container upcoming left">
                        <img
                            class="intro-screenshot lazyload"
                            data-src="/assets/images/screenshots/auswertungen.jpg"
                            alt="Screenshot Auswertungen" />
                        <div
                            class="screenshot-info-indicator indicator-1 fancy-tooltip"
                            title="Mehr als 10 Auswertungen geben dir den perfekten Überblick über dein Unternehmen. Z. B. zu Vermittlern, Kürzungen und Honoraren pro Mitarbeiter."></div>
                        <div
                            class="screenshot-info-indicator indicator-2 fancy-tooltip"
                            title="Für Buchhaltung & Steuerberater: Exportiere die Ergebnisse zu Excel & Co."></div>
                    </div>

                    <!--********** Contact Import **********-->
                    <div
                        id="intro-screenshot-container-contact-import"
                        class="intro-screenshot-container upcoming left">
                        <img
                            class="intro-screenshot lazyload"
                            data-src="/assets/images/screenshots/kontaktimport.jpg"
                            alt="Screenshot Kontaktimport" />
                        <div
                            class="screenshot-info-indicator indicator-1 fancy-tooltip"
                            title="Importiere Adressdaten über vordefinierte Schnittstellen oder kontaktiere uns für weitere."></div>
                    </div>

                    <!--********** Access Rights **********-->
                    <div id="intro-screenshot-container-access-rights" class="intro-screenshot-container upcoming left">
                        <img
                            class="intro-screenshot lazyload"
                            data-src="/assets/images/screenshots/zugriffsrechte.jpg"
                            alt="Screenshot Zugriffsrechte" />
                        <div
                            class="screenshot-info-indicator indicator-1 fancy-tooltip"
                            title="Zugriffsrechte sollten einfach sein. Vergib mit wenigen Klicks jedem Mitarbeiter die richtigen Rechte."></div>
                        <div
                            class="screenshot-info-indicator indicator-2 fancy-tooltip"
                            title="Lade neue Kollegen ganz einfach per Mail ein."></div>
                        <div
                            class="screenshot-info-indicator indicator-3 fancy-tooltip"
                            title="Passe dein Briefpapier selbständig an oder kontaktiere unsere Hotline. Wir helfen dir gerne."></div>
                    </div>

                    <!--============================================-->
                    <!-- Arrows -->
                    <!--============================================-->
                    <div id="screenshot-arrow-left-container" class="screenshot-arrow-container">
                        <img src="/assets/images/arrow-left-blue.svg" alt="" />
                    </div>
                    <div id="screenshot-arrow-right-container" class="screenshot-arrow-container">
                        <img id="screenshot-arrow-right-arrow" src="/assets/images/arrow-left-blue.svg" alt="" />
                        <img id="screenshot-arrow-right-checkmark" src="/assets/images/checkmark-white.svg" alt="" />
                    </div>
                    <!--============================================-->
                    <!-- END Arrows -->
                    <!--============================================-->
                </div>

                <div id="screenshots-additional-info-on-hover-note" class="d-none d-md-flex">
                    <div class="screenshot-info-indicator fancy-tooltip" title="So sehen Tooltips bei uns aus!"></div>
                    Fahre mit der Maus über die pulsierenden Kreise für mehr Infos.
                </div>

                <!--********** Duplication happens through jQuery **********-->
                <div id="intro-screenshots-counter-container">
                    <div class="intro-screenshots-counter"></div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--********** Core Features **********-->
<section id="core-features-section">
    <div class="container text-center">
        <div class="row">
            <div class="col-sm">
                <h3 class="additional-headline">Kern-Funktionen</h3>
                <h2 id="core-features-headline">Das Komplettpaket für Kfz-Gutachter</h2>
            </div>
        </div>

        <!--********** First Row **********-->
        <div class="row justify-content-center">
            <div class="col-6 col-md-2">
                <div class="core-feature-container">
                    <div class="core-feature-icon-container">
                        <img
                            src="/assets/images/icons/car-collision.png"
                            alt="Icon Haftpflichtschaden"
                            title="Das Haftpflichschäden-Tool für Kfz-Sachverständige & Kfz-Gutachter"
                            class="core-feature-icon fancy-tooltip" />
                    </div>
                    <h4 class="core-feature-title">Haftpflicht</h4>
                </div>
            </div>
            <div class="col-6 col-md-2">
                <div class="core-feature-container">
                    <div class="core-feature-icon-container">
                        <img
                            src="/assets/images/icons/classic-car.png"
                            alt="Icon Wertgutachten"
                            title="Wertgutachten, optional mit Classic Analytics"
                            class="core-feature-icon fancy-tooltip" />
                    </div>
                    <h4 class="core-feature-title">Wertgutachten</h4>
                </div>
            </div>
            <div class="col-6 col-md-2">
                <div class="core-feature-container">
                    <div class="core-feature-icon-container">
                        <img
                            src="/assets/images/icons/euro.png"
                            alt="Icon Fahrzeugbewertung"
                            title="Fahrzeugbewertung"
                            class="core-feature-icon fancy-tooltip" />
                    </div>
                    <h4 class="core-feature-title">Bewertung</h4>
                </div>
            </div>
            <div class="col-6 col-md-2">
                <div class="core-feature-container">
                    <div class="core-feature-icon-container">
                        <img
                            src="/assets/images/icons/thunderstorm-clouds.png"
                            alt="Icon Kaskoschäden"
                            title="Kaskoschäden für Kfz-Sachverständige"
                            class="core-feature-icon fancy-tooltip" />
                    </div>
                    <h4 class="core-feature-title">Kaskoschäden</h4>
                </div>
            </div>
        </div>

        <!--********** Divider **********-->
        <div class="row justify-content-center">
            <div class="col-sm-8 d-none d-md-block">
                <hr id="core-features-row-divider" />
            </div>
        </div>

        <!--********** Second Row **********-->
        <div class="row justify-content-center">
            <div class="col-6 col-md-2">
                <div class="core-feature-container">
                    <div class="core-feature-icon-container">
                        <img
                            src="/assets/images/icons/short-assessment.png"
                            alt="Icon Kurzgutachten"
                            title="Kurzgutachten für Gutachter im Kraftfahrzeugwesen"
                            class="core-feature-icon fancy-tooltip" />
                    </div>
                    <h4 class="core-feature-title">Kurzgutachten</h4>
                </div>
            </div>
            <div class="col-6 col-md-2">
                <div class="core-feature-container">
                    <div class="core-feature-icon-container">
                        <img
                            src="/assets/images/icons/credit-card.png"
                            alt="Icon Gutachten abrechnen"
                            title="Rechnungen für Gutachten automatisch generieren lassen, per Mail senden oder ausdrucken"
                            class="core-feature-icon fancy-tooltip" />
                    </div>
                    <h4 class="core-feature-title">Rechnungen</h4>
                </div>
            </div>
            <div class="col-6 col-md-2">
                <div class="core-feature-container">
                    <div class="core-feature-icon-container">
                        <img
                            src="/assets/images/icons/payment-reminder.png"
                            alt="Icon Gutachten-Mahnungen"
                            title="Mahnungen zu Gutachten schreiben"
                            class="core-feature-icon fancy-tooltip" />
                    </div>
                    <h4 class="core-feature-title">Mahnungen</h4>
                </div>
            </div>
            <div class="col-6 col-md-2">
                <div class="core-feature-container">
                    <div class="core-feature-icon-container">
                        <img
                            src="/assets/images/icons/reporting.png"
                            alt="Icon Auswertungen"
                            title="Durch Auswertungen dein Kfz-Sachverständigenbüro und deine Kunden besser kennenlernen"
                            class="core-feature-icon fancy-tooltip" />
                    </div>
                    <h4 class="core-feature-title">Auswertung</h4>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-10">
                <div id="features-link-container">
                    <ax-exclamation-box
                        headline="Alles, was du als Gutachter brauchst"
                        buttonTitle="Alle Funktionen"
                        buttonLink="/kfz-sachverstaendigen-software"
                        secondButtonTitle="Kostenfrei testen"
                        secondButtonLink="/registrierung"
                        [noPadding]="true">
                        Textbausteine, Kalkulationen, Buchhaltung & mehr.
                    </ax-exclamation-box>
                </div>
            </div>
        </div>
    </div>
</section>

<!--============================================-->
<!-- Customer Voices -->
<!--============================================-->
<section id="customer-voices-section" class="feature-section">
    <div class="container">
        <div class="row">
            <div class="col">
                <h3 class="additional-headline">Das sagt der Markt</h3>
                <h2>Deshalb schätzen Gutachter autoiXpert</h2>
                <p>Finde heraus, warum sich hunderte Sachverständige für autoiXpert entschieden haben.</p>
            </div>
        </div>
    </div>

    <!--********** Scroll to right **********-->
    <div class="customer-voices-scroll-wrapper">
        <div id="customer-voices-scroll-container-first" class="customer-voices-scroll-container">
            @for (customerVoice of customerVoices[0]; track customerVoice.quote) {
                <div class="customer-voice-container">
                    <div class="customer-voice-icon-container {{ customerVoice.color }}">
                        <img
                            class="customer-voice-icon"
                            src="/assets/images/icons/autoixpert-x-white.svg"
                            alt="autoiXpert Icon" />
                    </div>
                    <div class="customer-voice-text-container">
                        <div class="customer-voice">{{ customerVoice.quote }}</div>
                        <div class="customer-voice-author">{{ customerVoice.author }}</div>
                    </div>
                </div>
            }
            @for (customerVoice of customerVoices[1]; track customerVoice.quote) {
                <div class="customer-voice-container">
                    <div class="customer-voice-icon-container {{ customerVoice.color }}">
                        <img
                            class="customer-voice-icon"
                            src="/assets/images/icons/autoixpert-x-white.svg"
                            alt="autoiXpert Icon" />
                    </div>
                    <div class="customer-voice-text-container">
                        <div class="customer-voice">{{ customerVoice.quote }}</div>
                        <div class="customer-voice-author">{{ customerVoice.author }}</div>
                    </div>
                </div>
            }
        </div>
    </div>

    <!--********** Scroll to left **********-->
    <div class="customer-voices-scroll-wrapper">
        <div id="customer-voices-scroll-container-second" class="customer-voices-scroll-container">
            @for (customerVoice of customerVoices[1]; track customerVoice.quote) {
                <div class="customer-voice-container">
                    <div class="customer-voice-icon-container {{ customerVoice.color }}">
                        <img
                            class="customer-voice-icon"
                            src="/assets/images/icons/autoixpert-x-white.svg"
                            alt="autoiXpert Icon" />
                    </div>
                    <div class="customer-voice-text-container">
                        <div class="customer-voice">{{ customerVoice.quote }}</div>
                        <div class="customer-voice-author">{{ customerVoice.author }}</div>
                    </div>
                </div>
            }
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Customer Voices -->
<!--============================================-->
