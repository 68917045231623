import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ORGANIZATIONAL_TALENT_STRUCTURED_DATA } from './organisationstalent.google-structured-data';
import { GoogleStructuredDataComponent } from '../../../shared/components/google-structured-data.component';

@Component({
    selector: 'ax-organisationstalent',
    standalone: true,
    imports: [GoogleStructuredDataComponent],
    templateUrl: './organisationstalent.component.html',
    styleUrl: '../job-details.scss',
})
export class OrganisationstalentComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    protected googleStructuredData = ORGANIZATIONAL_TALENT_STRUCTURED_DATA;

    constructor() {
        this.titleService.setTitle('Organisationstalent | Jobs');
        this.metaService.addTag({
            name: 'description',
            content:
                'Bist du smart und hast schon mehrere Jahre in der Orga eines Unternehmens gearbeitet? Bring deine Karriere mit autoiXpert auf die nächste Stufe und arbeite in einem jungen dynamischen Team.',
        });
    }
}
