export const COST_PER_ADDITIONAL_USER = 20;
export const COST_PER_ADDITIONAL_USER_AUDATEX_ADDON = 10;
export const AUDATEX_ADDON_COSTS = 30;
export const AUTOIXPERT_BASE_ACCOUNT_FEE = 89;
export const MARKET_VALUE_ANALYSIS_COST = 2.5;
export const RESIDUAL_VALUE_REQUEST_COST = 18;
export type CalculationProvider = 'audatex' | 'dat' | 'both';
export const REPORTS_PER_MONTH_QUERY_PARAM_NAME = 'numberOfReports';
export const REPORTS_PER_MONTH_AUDATEX_QUERY_PARAM_NAME = 'numberOfReportsAudatex';
export const NUMBER_OF_USERS_QUERY_PARAM_NAME = 'numberOfUsers';
export const CALCULATION_PROVIDER_QUERY_PARAM_NAME = 'calculationProvider';
export const INCLUDE_MARKET_AND_RESIDUAL_VALUE_QUERY_PARAM_NAME = 'includeOthers';
