import { Routes } from '@angular/router';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { IndexComponent } from './pages/index/index.component';
import { KfzSachverstaendigenSoftwareComponent } from './pages/kfz-sachverstaendigen-software/kfz-sachverstaendigen-software.component';
import { TeamComponent } from './pages/team/team.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { SeniorDeveloperComponent } from './pages/jobs/senior-developer/senior-developer.component';
import { OrganisationstalentComponent } from './pages/jobs/organisationstalent/organisationstalent.component';
import { CustomerSupportAgentComponent } from './pages/jobs/customer-support-agent/customer-support-agent.component';
import { JuniorDeveloperComponent } from './pages/jobs/junior-developer/junior-developer.component';
import { DeveloperComponent } from './pages/jobs/developer/developer.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import { NutzungsbedingungenComponent } from './pages/nutzungsbedingungen/nutzungsbedingungen.component';
import { GutachterWerdenComponent } from './pages/gutachter-werden/gutachter-werden.component';
import { TeilnahmebedingungenComponent } from './pages/teilnahmebedingungen/teilnahmebedingungen.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PreiseComponent } from './pages/preise/preise.component';
import { AudatexPreisrechnerComponent } from './pages/preise/audatex-preisrechner/audatex-preisrechner.component';
import { DatPreisrechnerComponent } from './pages/preise/dat-preisrechner/dat-preisrechner.component';
import { GtmotivePreiseComponent } from './pages/preise/gtmotive-preise/gtmotive-preise.component';
import { RegistrierungComponent } from './pages/registrierung/registrierung.component';
import { PageWithHeaderAndFooterComponent } from './pages/page-with-header-and-footer/page-with-header-and-footer.component';
import { AudatexPartnerschaftComponent } from './pages/audatex-partnerschaft/audatex-partnerschaft.component';
import { ModalComponent } from './pages/preise/modal/modal.component';
import { ProduktWebinarComponent } from './pages/webinars/produkt-webinar/produkt-webinar.component';
import { AbtretungMackKohlComponent } from './pages/webinars/fachwebinare/abtretung-mack-kohl/abtretung-mack-kohl.component';
import { AufbauwebinarFahrzeugbewertungKohlComponent } from './pages/webinars/fachwebinare/aufbauwebinar-fahrzeugbewertung-kohl/aufbauwebinar-fahrzeugbewertung-kohl.component';
import { FallstrickeSchadenkalkulationKohlComponent } from './pages/webinars/fachwebinare/fallstricke-schadenkalkulation-kohl/fallstricke-schadenkalkulation-kohl.component';
import { GrundlagenFahrzeugbewertungKohlComponent } from './pages/webinars/fachwebinare/grundlagen-fahrzeugbewertung-kohl/grundlagen-fahrzeugbewertung-kohl.component';
import { GutachtentexteKohlComponent } from './pages/webinars/fachwebinare/gutachtentexte-kohl/gutachtentexte-kohl.component';
import { Kasko1KohlComponent } from './pages/webinars/fachwebinare/kasko-1-kohl/kasko-1-kohl.component';
import { Kasko2KohlComponent } from './pages/webinars/fachwebinare/kasko-2-kohl/kasko-2-kohl.component';
import { MinderwertKohlComponent } from './pages/webinars/fachwebinare/minderwert-kohl/minderwert-kohl.component';
import { NachtexteKohlComponent } from './pages/webinars/fachwebinare/nachtexte-kohl/nachtexte-kohl.component';
import { VortexteKohlComponent } from './pages/webinars/fachwebinare/vortexte-kohl/vortexte-kohl.component';
import { OldtimerDeuschleComponent } from './pages/webinars/fachwebinare/oldtimer-deuschle/oldtimer-deuschle.component';
import { RestwertKohlComponent } from './pages/webinars/fachwebinare/restwert-kohl/restwert-kohl.component';
import { StellungnahmenKohlComponent } from './pages/webinars/fachwebinare/stellungnahmen-kohl/stellungnahmen-kohl.component';
import { ZustandsberichteKohlComponent } from './pages/webinars/fachwebinare/zustandsberichte-kohl/zustandsberichte-kohl.component';
import { ZweiradgutachtenKohlComponent } from './pages/webinars/fachwebinare/zweiradgutachten-kohl/zweiradgutachten-kohl.component';
import { WiederbeschaffungswertKohlComponent } from './pages/webinars/fachwebinare/wiederbeschaffungswert-kohl/wiederbeschaffungswert-kohl.component';
import { AudatexKalkulationKohlComponent } from './pages/webinars/fachwebinare/audatex-kalkulation-kohl/audatex-kalkulation-kohl.component';
import { HaftungsfaelleHaftungsfalleKohlComponent } from './pages/webinars/fachwebinare/haftungsfaelle-haftungsfalle-kohl/haftungsfaelle-haftungsfalle-kohl.component';
import { DatKalkulationKohlComponent } from './pages/webinars/fachwebinare/dat-kalkulation-kohl/dat-kalkulation-kohl.component';
import { BuchhaltungSchlieferComponent } from './pages/webinars/fachwebinare/buchhaltung-schliefer/buchhaltung-schliefer.component';
import { SupportAndProductManager } from './pages/jobs/technical-support-agent/support-and-product-manager.component';
import { GesamtkostenrechnerComponent } from './pages/preise/gesamtkostenrechner/gesamtkostenrechner.component';
import { NeuerungenInAutoixpertSchlieferComponent } from './pages/webinars/fachwebinare/neuerungen-in-autoixpert-schliefer/neuerungen-in-autoixpert-schliefer.component';
import { PowerUserDokumenteSchlieferComponent } from './pages/webinars/fachwebinare/power-user-dokumente-schliefer/power-user-dokumente-schliefer.component';
import { FuerWechslerComponent } from './pages/fuer-wechsler/fuer-wechsler.component';

/**
 * Don't split these routes into separate files unless you adjust the logic in get-canonical-url.ts.
 */
export const routes: Routes = [
    // Pages without Header + Footer
    {
        path: 'Registrierung',
        component: RegistrierungComponent,
    },
    // Pages with Header + Footer
    {
        path: '',
        component: PageWithHeaderAndFooterComponent,
        children: [
            {
                path: '',
                component: IndexComponent,
            },
            {
                path: 'Impressum',
                component: ImpressumComponent,
            },
            {
                path: 'Kfz-Sachverstaendigen-Software',
                component: KfzSachverstaendigenSoftwareComponent,
            },
            {
                path: 'Preise',
                component: PreiseComponent,
            },
            {
                path: 'Fuer-Wechsler',
                component: FuerWechslerComponent,
            },
            {
                path: 'Gesamtkostenrechner',
                component: GesamtkostenrechnerComponent,
            },
            {
                path: 'Audatex-Preisrechner',
                component: AudatexPreisrechnerComponent,
            },
            {
                path: 'Dat-Preisrechner',
                component: DatPreisrechnerComponent,
            },
            {
                path: 'Gtmotive-preise',
                component: GtmotivePreiseComponent,
            },
            {
                path: 'Modal',
                component: ModalComponent,
            },
            {
                path: 'Team',
                component: TeamComponent,
            },
            {
                path: 'Webinar',
                component: ProduktWebinarComponent,
            },
            {
                path: 'Fachwebinare/Abtretung-Mack-Kohl',
                component: AbtretungMackKohlComponent,
            },
            {
                path: 'Fachwebinare/Audatex-Kalkulation-Kohl',
                component: AudatexKalkulationKohlComponent,
            },
            {
                path: 'Fachwebinare/Aufbauwebinar-Fahrzeugbewertung-Kohl',
                component: AufbauwebinarFahrzeugbewertungKohlComponent,
            },
            {
                path: 'Fachwebinare/Buchhaltung-Schliefer',
                component: BuchhaltungSchlieferComponent,
            },
            // {
            //     path: 'Fachwebinare/Dokumentengestaltung-Schliefer',
            //     component: DokumentengestaltungSchlieferComponent,
            // },
            // {
            //     path: 'Fachwebinare/Erfahrungsaustausch-fuer-Sachverstaendige-Schliefer',
            //     component: ErfahrungsaustauschSchlieferComponent,
            // },
            {
                path: 'Fachwebinare/Fallstricke-Schadenkalkulation-Kohl',
                component: FallstrickeSchadenkalkulationKohlComponent,
            },
            {
                path: 'Fachwebinare/Grundlagen-Fahrzeugbewertung-Kohl',
                component: GrundlagenFahrzeugbewertungKohlComponent,
            },
            {
                path: 'Fachwebinare/Gutachtentexte-Kohl',
                component: GutachtentexteKohlComponent,
            },
            {
                path: 'Fachwebinare/Haftungsfälle-Haftungsfalle-Kohl',
                component: HaftungsfaelleHaftungsfalleKohlComponent,
            },
            {
                path: 'Fachwebinare/Kalkulation-DAT-Kohl',
                component: DatKalkulationKohlComponent,
            },
            {
                path: 'Fachwebinare/Kasko-1-Kohl',
                component: Kasko1KohlComponent,
            },
            {
                path: 'Fachwebinare/Kasko-2-Kohl',
                component: Kasko2KohlComponent,
            },
            {
                path: 'Fachwebinare/Gutachtentexte-Kohl',
                component: GutachtentexteKohlComponent,
            },
            {
                path: 'Fachwebinare/Minderwert-Kohl',
                component: MinderwertKohlComponent,
            },
            {
                path: 'Fachwebinare/Nachtexte-Kohl',
                component: NachtexteKohlComponent,
            },
            {
                path: 'Fachwebinare/Neuerungen-in-autoiXpert-Schliefer',
                component: NeuerungenInAutoixpertSchlieferComponent,
            },
            {
                path: 'Fachwebinare/Power-User-Dokumente-Schliefer',
                component: PowerUserDokumenteSchlieferComponent,
            },
            {
                path: 'Fachwebinare/Vortexte-Kohl',
                component: VortexteKohlComponent,
            },
            {
                path: 'Fachwebinare/Oldtimer-Deuschle',
                component: OldtimerDeuschleComponent,
            },
            {
                path: 'Fachwebinare/Restwert-Kohl',
                component: RestwertKohlComponent,
            },
            {
                path: 'Fachwebinare/Stellungnahmen-Kohl',
                component: StellungnahmenKohlComponent,
            },
            {
                path: 'Fachwebinare/Zustandsberichte-Kohl',
                component: ZustandsberichteKohlComponent,
            },
            {
                path: 'Fachwebinare/Zweiradgutachten-Kohl',
                component: ZweiradgutachtenKohlComponent,
            },
            {
                path: 'Fachwebinare/Wiederbeschaffungswert-Kohl',
                component: WiederbeschaffungswertKohlComponent,
            },
            {
                path: 'Jobs',
                component: JobsComponent,
            },
            {
                path: 'Jobs/Senior-Developer',
                component: SeniorDeveloperComponent,
            },
            {
                path: 'Jobs/Developer',
                component: DeveloperComponent,
            },
            {
                path: 'Jobs/Junior-Developer',
                component: JuniorDeveloperComponent,
            },
            {
                path: 'Jobs/Organisationstalent',
                component: OrganisationstalentComponent,
            },
            {
                path: 'Jobs/Customer-Support-Agent',
                component: CustomerSupportAgentComponent,
            },
            {
                path: 'Jobs/Produkt-und-Support-Manager',
                component: SupportAndProductManager,
            },
            {
                path: 'Kontakt',
                component: KontaktComponent,
            },
            {
                path: 'Datenschutz',
                component: DatenschutzComponent,
            },
            {
                path: 'Nutzungsbedingungen',
                component: NutzungsbedingungenComponent,
            },
            {
                path: 'Gutachter-werden',
                component: GutachterWerdenComponent,
            },
            {
                path: 'Audatex-Partnerschaft',
                component: AudatexPartnerschaftComponent,
            },
            {
                path: 'Teilnahmebedingungen',
                component: TeilnahmebedingungenComponent,
            },
            {
                path: '404',
                component: NotFoundComponent,
            },
            /**
             * 404 page
             */
            { path: '**', pathMatch: 'full', component: NotFoundComponent },
        ],
    },
];
